import { ICacheService } from 'original-works-core/models'

import { CacheEntry } from './CacheEntry'

let nextId = 0
export function generateId () {
  return `id_${nextId++}`
}

export class CacheService implements ICacheService {
  private temporaryKeyStorage: Record<string, any> = {}

  createEntry<T> (initialValue: T): CacheEntry<T>
  createEntry<T> (): CacheEntry<T | undefined>
  createEntry<T> (initialValue?: T) {
    return new CacheEntry(
      this.temporaryKeyStorage,
      generateId(),
      initialValue,
    )
  }
}
