import React, { ChangeEvent, useState } from 'react'

import { ApiUserData } from 'original-works-core/models'

import { Button, ProfileImage } from 'src/ui/common'
import { useServices } from 'src/ui/hooks'
import arrowIcon from 'src/assets/icons/arrow_white--long.svg'

export interface EnterAmountProps {
  id: string,
  user: ApiUserData,
}

export function EnterAmount ({ id, user }: EnterAmountProps) {
  const [value, setValue] = useState('')
  const [isValid, setIsValid] = useState(false)
  const { stripeService } = useServices()

  async function handlePayment () {
    if (isValid) {
      await stripeService.redirectToCheckout(id, value.slice(1))
    }
  }

  function handleInputChange (e: ChangeEvent<HTMLInputElement>) {
    if (e.target.value.slice(0, 1) !== '$') {
      e.target.value = '$' + e.target.value
    }
    const rawValue = parseFloat(e.target.value.slice(1)) || 0

    if (/^\$\d*(\.\d{0,2})?$/.test(e.target.value) && rawValue <= 50) {
      setValue(`$${parseInt(rawValue.toString())}${parseDecimal(e.target.value)}`)
      setIsValid(rawValue > 0)
    }
  }

  return (
    <div className="pay-to-user_modal_enter-amount-content-wrapper">
      <div className="pay-to-user_modal_enter-amount_user">
        <ProfileImage src={user.imageURL} className="pay-to-user_modal_enter-amount_user-avatar" />
        <p className="pay-to-user_modal_enter-amount_user-name">{user.name}</p>
        <p className="pay-to-user_modal_enter-amount_user-payment-link">original.works/{id}</p>
      </div>
      <div className="pay-to-user_modal_enter-amount-content">
        <input
          type="text"
          value={value}
          placeholder="$0"
          onChange={handleInputChange}
          className="pay-to-user_modal_enter-amount-input"
        />
        <p className="pay-to-user_modal-note pay-to-user_modal_enter-amount-currency">USD</p>
      </div>
      <Button
        className="pay-to-user_modal_enter-amount_button"
        onClick={handlePayment}
        disabled={!isValid}
      >
        Next
        <img src={arrowIcon} alt="arrow" />
      </Button>
    </div>
  )
}

function parseDecimal (value: string) {
  return value.slice(value.indexOf('.') !== -1 ? value.indexOf('.') : value.length)
}
