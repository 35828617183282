import { getDefaultProvider } from 'ethers'

import { Config } from './Config'

export function getMainnetConfig (): Config {
  return {
    apiUrl: 'https://app-api.original.works',
    network: 'mainnet',
    provider: getDefaultProvider('homestead'),
    nativeCoin: 'ETH',
    OWNTokenAddress: '0x4EcDFDD3114BC656359f3521eE06c1F9Cbf9f646',
    cashoutAddress: '0x43f15e5539eC4B4394f7897F35975C98Ff67DFD4',
    factoryAddress: '0x3df3a7Cf586562910e986Ec2Ea5255a6DdfdA5B3',
    feeManagerAddress: '0x236fF1Bc8fC8700dd0B0a162e1548C094fBf0E05',
    stripeKey: 'pk_test_4DmGPmpaGOHr92X4uL2IxoKc',

    // do not use values below

    campaignId: -1,
    campaignApiKey: '',
  }
}
