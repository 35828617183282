import { getDefaultProvider } from 'ethers'

import { Config } from './Config'

export function getTestConfig (): Config {
  return {
    apiUrl: 'http://localhost:8080',
    network: 'kovan',
    provider: getDefaultProvider('kovan'),
    nativeCoin: 'ETH',
    OWNTokenAddress: '0x0000000000000000000000000000000000000000',
    cashoutAddress: '0x0000000000000000000000000000000000000001',
    factoryAddress: '0x0000000000000000000000000000000000000000',
    feeManagerAddress: '0x0000000000000000000000000000000000000000',
    campaignId: 0,
    campaignApiKey: '',
    stripeKey: '',
  }
}
