import { getRopstenConfig } from './config.ropsten'
import { getKovanConfig } from './config.kovan'
import { getOwnetConfig } from './config.ownet'
import { getMainnetConfig } from './config.mainnet'
import { getBinanceConfig } from './config.binance'
import { getTestConfig } from './config.test'

function getConfig (network: string) {
  switch (network) {
    case 'ropsten': return getRopstenConfig()
    case 'kovan': return getKovanConfig()
    case 'ownet': return getOwnetConfig()
    case 'mainnet': return getMainnetConfig()
    case 'binance': return getBinanceConfig()
    case 'test': return getTestConfig()
  }
  throw TypeError(`Invalid network ${network}`)
}

function getNetwork () {
  if (process.env.NETWORK) {
    return process.env.NETWORK
  } else if (process.env.NODE_ENV === 'test') {
    return 'test'
  } else {
    return 'ropsten'
  }
}

export default getConfig(getNetwork())
