import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { ApiAgreement } from 'original-works-core/models'
import { CurrencyValue } from 'original-works-core/utils'

import musicNote from 'src/assets/icons/music-note.svg'
import { useAsync, useServices } from 'src/ui/hooks'
import { PayModal } from '../PayModal/PayModal'
import { getCover } from '../covers'

interface AgreementListItemProps {
  agreement: ApiAgreement,
}

export function AgreementListItem ({ agreement }: AgreementListItemProps) {
  const { payoutQueryService, teostoPayoutService, exchangeRateService } = useServices()

  const [modalVisible, setModalVisible] = useState(false)
  const [payoutInfo] = useAsync(() => payoutQueryService.getPayouts(agreement.address), [agreement.address])
  const { totalPaid } = payoutInfo || { totalPaid: new CurrencyValue(0, 'OWN') }

  const totalPaidUSD = exchangeRateService.toUSD(totalPaid)

  const [nextPayoutValue, error] = useAsync(
    () => teostoPayoutService.getPayoutAmount(agreement.address),
    [agreement.address],
  )

  const payoutUSD = nextPayoutValue && exchangeRateService.toUSD(nextPayoutValue)

  return (
    <li className="agreement-item teosto">
      <img className="agreement-item__image teosto" src={getCover(agreement.address)} />
      <Link className="agreement-item__main teosto" to={`/teosto/agreements/${agreement.address}`}>
        <span className="agreement-item__title teosto">{agreement.title}</span>
        <span className="agreement-item__description teosto">
          <img className="agreement-item__icon teosto" src={musicNote} />
          {agreement.title}
        </span>
      </Link>
      <span className="agreement-item__balance teosto">${totalPaidUSD.formatWithTicker()}</span>
      <span className="agreement-item__info teosto">
        {!error && (payoutUSD ? `$${payoutUSD.formatWithTicker()}` : '...')}
        {error && <span className="agreement-item__error teosto">Error</span>}
      </span>
      <button
        className="button agreement-item__button teosto"
        onClick={() => setModalVisible(true)}
      >
        Pay royalty
      </button>
      {modalVisible && (
        <PayModal
          requiredPayment={nextPayoutValue || new CurrencyValue(0, 'EUR')}
          agreement={agreement}
          onClose={() => setModalVisible(false)}
          displayEUR
        />
      )}
    </li>
  )
}
