import React from 'react'

interface SignInCheckboxProps {
  remember: boolean,
  setRemember: (remember: boolean) => void,
}

export function SignInCheckbox ({ remember, setRemember }: SignInCheckboxProps) {
  return (
    <label className="sign-in-checkbox">
      <input
        className="sign-in-checkbox__input"
        type="checkbox"
        checked={remember}
        onChange={e => setRemember(e.target.checked)}
      />
      <span className="sign-in-checkbox__keep-signed">Keep me signed in</span>
    </label>
  )
}
