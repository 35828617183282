import React, { useEffect, useState } from 'react'
import copy from 'copy-to-clipboard'
import { Dropdown } from 'src/ui/common'

export interface OptionsMenuProps {
  contractAddress: string,
}

export function OptionsMenu ({ contractAddress }: OptionsMenuProps) {
  const [copied, setCopied] = useState<boolean>()

  useEffect(() => {
    if (copied) {
      const timeoutId = setTimeout(() => setCopied(false), 3000)

      return () => clearTimeout(timeoutId)
    }
  }, [copied])

  function preventEventBubbling (e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation()
    e.preventDefault()
  }

  function copyAddress (e: React.MouseEvent<HTMLDivElement>) {
    preventEventBubbling(e)
    if (!copied) {
      copy(contractAddress)
      setCopied(true)
    }
  }

  const options = [
    { label: 'Send payment', onSelect: preventEventBubbling, inactive: true },
    { label: 'Transfer shares', onSelect: preventEventBubbling, inactive: true },
    { label: 'Show QR code', onSelect: preventEventBubbling, inactive: true },
    { label: copied ? 'Copied!' : 'Copy address', onSelect: copyAddress },
    { label: 'Report issue', onSelect: preventEventBubbling, inactive: true },
  ]

  return <Dropdown options={options} />
}
