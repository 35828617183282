import React from 'react'
import { Switch } from 'src/ui/common'
import { bem } from 'bem-components-react'

export interface DistributeEvenlyProps {
  value: boolean,
  onChange (value: boolean): void,
}

export function DistributeEvenly ({ value, onChange }: DistributeEvenlyProps) {
  return (
    <Container>
      <Label>Distribute shares evenly</Label>
      <Switch value={value} onChange={onChange} />
    </Container>
  )
}

const Container = bem.div('distribute-evenly')
const Label = bem.p('distribute-evenly__label')
