import React from 'react'
import { useServices } from 'src/ui/hooks'
import styled from 'styled-components'

import logo from 'src/assets/placeholders/originalWorks.png'

export function Footer () {
  const { accountService } = useServices()
  const isSignedIn = accountService.isSignedIn()
  const year = new Date().getFullYear()

  return (
    <Container>
      <Section>
        <Logo src={logo} alt="Logo" />
      </Section>
      <NavSection>
        {isSignedIn
          ? (
            <div>
              <Link href="/">Home</Link>
              <Link href="/assets">Assets</Link>
              <Link href="/agreements">Agreements</Link>
              <Link>Activity</Link>
            </div>
          )
          : (
            <div>
              <Link>Wallet</Link>
              <Link>Token</Link>
              <Link>Platform</Link>
              <Link>Spaces</Link>
            </div>
          )
        }
        <div>
          <Link href="https://original.works/en/about">About</Link>
          <Link href="https://original.works/en/contact">Contact</Link>
        </div>
      </NavSection>
      <Section>
        <LinkWithDot href="https://twitter.com/originalworks_n">Twitter</LinkWithDot>
        <LinkWithDot href="https://www.facebook.com/">Facebook</LinkWithDot>
        <LinkWithDot href="https://www.linkedin.com/company/revelator-enterprises/">LinkedIn</LinkWithDot>
        <LinkWithDot href="https://own-community.slack.com/">Slack</LinkWithDot>
        <LinkWithDot href="https://www.youtube.com/">YouTube</LinkWithDot>
      </Section>
      <CopyrightSection>
        <span>{year} © Original Works Inc.</span>
        <span>Privacy Policy</span>
      </CopyrightSection>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  margin: 0 80px;
  padding-bottom: 40px;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 80px;
  font-size: 14px;
  line-height: 1.71;
  color: #888888;
`

const Section = styled.div`
  padding-top: 32px;
  border-top: 1px solid #e2e2e2;
`

const NavSection = styled(Section)`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const CopyrightSection = styled(Section)`
  & > *:not(:first-child) {
    margin-left: 17px;
  }
`

const Logo = styled.img`
  width: 118px;
  height: 17px;
`

const Link = styled.a`
  display: block;
  font-size: 14px;
  line-height: 1.71;
  font-weight: 500;
  color: #2b2b2b;
  &:hover {
    color: #7341f3;
  }
`

const LinkWithDot = styled(Link)`
  display: inline;
  &:not(:first-child)::before {
    content: "·";
    position: relative;
    padding: 0 4px;
  }
`
