import React, { ReactNode, MouseEvent } from 'react'
import { bem } from 'bem-components-react'

export interface DropdownProps {
  options: Option[],
}

export interface Option {
  label: string,
  onSelect: (e: MouseEvent<HTMLDivElement>) => void,
  inactive?: boolean,
}

export interface OptionProps extends Omit<Option, 'label'>{
  children: ReactNode,
}

export function Dropdown ({ options }: DropdownProps) {
  return (
    <div className="dropdown">
      {options.map(option => (
        <OptionComponent
          onSelect={option.onSelect}
          inactive={option.inactive}
          key={option.label}
        >
          {option.label}
        </OptionComponent>
      ))}
    </div>
  )
}

function OptionComponent ({ children, onSelect, inactive }: OptionProps) {
  return (
    <OptionContainer onClick={onSelect} inactive={inactive}>
      <p className="dropdown-option__label">{children}</p>
    </OptionContainer>
  )
}

const OptionContainer = bem.div('dropdown-option', ['inactive'])
