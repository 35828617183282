import React from 'react'
import checkmarkIcon from 'src/assets/icons/checkmark-ico_light-blue.svg'

export function Checkmark () {
  return (
    <div className="checkmark">
      <img src={checkmarkIcon} alt="checkmark" />
    </div>
  )
}
