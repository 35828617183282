import React, { useEffect, useState, ReactNode } from 'react'
import cx from 'classnames'

import searchIcon from 'src/assets/icons/search--white.png'

export interface NavigationOption {
  label: string,
  active: boolean,
}

export interface ListNavigationProps {
  title: string,
  onSearchClick: () => void,
  children: ReactNode,
}

export function ListNavigation ({ title, onSearchClick, children }: ListNavigationProps) {
  const [showButton, setShowButton] = useState(false)

  useEffect(() => {
    function handleScroll () {
      if (pageYOffset >= 174 && !showButton) {
        setShowButton(true)
      } else if (pageYOffset < 174 && showButton) {
        setShowButton(false)
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [showButton])

  function onClick () {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    onSearchClick()
  }

  return (
    <div>
      <div className="list-navigation">
        <p className="list-navigation__title">{title}</p>
        {children}
        <button
          className={cx('button', 'search-button', showButton && 'search-button--visible')}
          onClick={onClick}
        >
          Search
          <img src={searchIcon} alt="search" />
        </button>
      </div>
    </div>
  )
}
