import React from 'react'
import { Redirect } from 'react-router'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { hot } from 'react-hot-loader/root'

import { PrivateRoute } from './PrivateRoute'
import { AdminRoute } from './AdminRoute'

import { SignInModal } from './SignIn/SignInModal'
import { SignIn } from './SignIn/SignIn'
import CreateAgreement from './CreateAgreement/CreateAgreement'
import Cashouts from './Admin/Cashouts/Cashouts'
import { AgreementsPage } from './Teosto/Agreements/AgreementsPage'
import { AgreementInfoPage } from './Teosto/AgreementDetails/AgreementInfoPage'
import { PartnerAgreements } from './Admin/PartnerAgreements/PartnerAgreements'
import { RedeemCodes } from './Admin/RedeemCodes'
import { ContractScreen } from './Contract/ContractScreen'
import { AgreementScreen } from './Contract/AgreementScreen'
import { AssetScreen } from './Contract/AssetScreen'
import { AssetsListPage, AgreementsListPage } from './Contracts'
import { AdminFees } from './Admin/Fees/AdminFees'
import { EmailConfirmed } from './EmailConfirmation/EmailConfirmed'
import { EmailConfirmationError } from './EmailConfirmation/EmailConfirmationError'
import { PageNotFound } from './PageNotFound'
import { InitiatePaymentPage } from './PayToUser/InitiatePaymentPage'
import { SuccessPaymentPage } from './PayToUser/SuccessPaymentPage'
import { MicroLending } from './Admin/MicroLending/MicroLending'

function AppComponent () {
  return (
    <>
      <SignInModal />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </>
  )
}

function Routes () {
  return (
    <Switch>
      <Redirect exact from="/" to="/agreements" />

      <AdminRoute exact path="/admin/fees" component={AdminFees} />
      <AdminRoute exact path="/admin/cashouts" component={Cashouts} />
      <AdminRoute exact path="/admin/scan-and-register" component={PartnerAgreements} />
      <AdminRoute exact path="/admin/redeem" component={RedeemCodes} />
      <AdminRoute exact path="/admin/micro-lending" component={MicroLending} />

      <Route exact path="/sign-in" component={SignIn} />
      <Route exact path="/contract/:address" component={ContractScreen}/>
      <Route exact path="/agreement/:address" component={AgreementScreen}/>
      <Route exact path="/asset/:address" component={AssetScreen}/>
      <Route exact path="/email-confirmed" component={EmailConfirmed}/>
      <Route exact path="/email-verification-failed" component={EmailConfirmationError} />
      <Route exact path="/pay/:id" component={InitiatePaymentPage} />
      <Route exact path="/pay-success" component={SuccessPaymentPage} />

      <PrivateRoute exact path="/create-agreement" component={CreateAgreement} />
      <PrivateRoute exact path="/teosto/agreements" component={AgreementsPage} />
      <PrivateRoute exact path="/teosto/agreements/:address" component={AgreementInfoPage} />
      <PrivateRoute exact path="/assets" component={AssetsListPage} />
      <PrivateRoute exact path="/agreements" component={AgreementsListPage} />

      <Redirect exact from="/teosto" to="/teosto/agreements" />
      <Redirect exact from="/admin" to="/admin/fees" />
      {/* Old URLs */}
      <Redirect exact from="/login" to="/sign-in" />
      <Redirect exact from="/cashouts" to="/admin/cashouts" />
      <Redirect exact from="/partner-agreements" to="/admin/scan-and-register" />
      <Redirect exact from="/agreements-payout" to="/admin/payout" />

      <Route component={PageNotFound} />
    </Switch>
  )
}

export default hot(AppComponent)
