import React from 'react'

import { ApiActivityAdminAdded, ApiActivityAdminRemoved, ApiContract, ApiUserData } from 'original-works-core/models'

import { ActivityModal } from './ActivityModal'
import { ProfileField, DetailsField, AssetField, AgreementField } from './fields'

export interface AdminChangedModalProps {
  isVisible: boolean,
  onClose: () => void,
  activity: ApiActivityAdminAdded | ApiActivityAdminRemoved,
  user?: ApiUserData,
  initiator?: ApiUserData,
  contract: ApiContract,
}

export function AdminChangedModal (props: AdminChangedModalProps) {
  const isAdminAdded = props.activity.type === 'ADMIN_ADDED'

  return (
    <ActivityModal
      isVisible={props.isVisible}
      onClose={props.onClose}
      title={isAdminAdded ? 'Admin added' : 'Admin removed'}
    >
      <ProfileField profile={props.user} title={isAdminAdded ? 'Admin added' : 'Admin removed'} />
      <ProfileField profile={props.initiator} title={isAdminAdded ? 'Added by' : 'Removed by'} />
      <DetailsField timestamp={props.activity.timestamp} />
      {props.contract.type === 'ASSET'
        ? <AssetField contract={props.contract} title="To asset" bottom />
        : <AgreementField contract={props.contract} title="To agreement" bottom />
      }
    </ActivityModal>
  )
}
