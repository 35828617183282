import React from 'react'
import cx from 'classnames'

export interface ContractIconProps {
  src: string,
  className?: string,
}

export function ContractIcon ({ src, className }: ContractIconProps) {
  return <img src={src} alt="contract" className={cx('contract-icon', className)} />
}
