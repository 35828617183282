import React, { useMemo } from 'react'

import { MapKeyBar } from './MapKeyBar'
import { SummaryStats } from './SummaryStats'
import { PayoutActivity } from 'original-works-core/services'
import { toEarningsSummaryData, Period } from 'original-works-core/utils'
import { ApiFinancialFactsheet } from 'original-works-core/models'

export interface SummaryProps {
  period: Period,
  isAsset: boolean,
  payouts?: PayoutActivity[],
  financials?: ApiFinancialFactsheet,
}

export function Summary ({ period, isAsset, payouts, financials }: SummaryProps) {
  const summaryData = useMemo(() => toEarningsSummaryData(payouts, financials), [payouts, financials])

  return (
    <div className="eh-summary__wrapper">
      {isAsset && <MapKeyBar period={period} />}
      <SummaryStats period={period} isAsset={isAsset} summaryData={summaryData}/>
    </div>
  )
}
