import React from 'react'

import menu from 'src/assets/icons/menu.svg'
import { Dropdown } from 'src/ui/common'

export interface NavbarDropdownProps {
  signedIn: boolean,
  signIn: () => void,
  signOut: () => void,
}

export function NavbarDropdown ({ signedIn, signIn, signOut }: NavbarDropdownProps) {
  const options = [
    { label: 'Help & Support', onSelect: () => window.location.assign('https://original.works/en/contact') },
    { label: signedIn ? 'Sign out' : 'Sign in', onSelect: signedIn ? signOut : signIn },
  ]

  return (
    <div className="navbar-dropdown">
      <img className="navbar-dropdown__button" src={menu} alt="menu" />
      <Dropdown options={options} />
    </div>
  )
}
