import React, { useState } from 'react'
import cx from 'classnames'

import { EarningsHistoryChart } from './EarningsHistoryChart'
import { PeriodSelector } from './PeriodSelector'
import { Summary } from './Summary'
import { PayoutActivity } from 'original-works-core/services'
import { Period } from 'original-works-core/utils'
import { useServices } from 'src/ui/hooks'
import { ApiFinancialFactsheet } from 'original-works-core/models'
import { getHiddenDataString } from '../../utils'

export interface EarningsHistoryBlockProps {
  isAsset: boolean,
  payouts?: PayoutActivity[],
  financials?: ApiFinancialFactsheet,
  isAllowedToView?: boolean,
  assetType?: string,
}

export function EarningsHistoryBlock ({
  assetType,
  isAllowedToView,
  isAsset,
  payouts,
  financials,
}: EarningsHistoryBlockProps) {
  const [period, setPeriod] = useState<Period>('monthly')
  const { accountService } = useServices()
  const isSignedIn = accountService.isSignedIn()

  return (
    <div className={cx('earnings-history', !isAllowedToView && 'earnings-history--blurred')}>
      <PeriodSelector value={period} onChange={setPeriod} />
      <EarningsHistoryChart
        period={period}
        isAsset={isAsset}
        payouts={payouts}
        financials={financials}
      />
      <Summary
        period={period}
        isAsset={isAsset}
        payouts={payouts}
        financials={financials}
      />
      <div className="earnings-history__signed-out">
        <span className="earnings-history__signed-out-title">
          {`${isSignedIn ? 'Holder rights' : 'Sign in'} required`}
        </span>
        <span className="earnings-history__signed-out-text">
          {getHiddenDataString(
            'the earnings history for',
            isSignedIn,
            isAsset,
            assetType && assetType.toLowerCase(),
          )}
        </span>
      </div>
    </div>
  )
}
