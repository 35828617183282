import React from 'react'
import { bem } from 'bem-components-react'

export function EmptyAssets () {
  return (
    <div className="empty-list">
      <div className="empty-list__top-area">
        <p className="empty-list__top-area-text">
          Create, manage, and exchange value with <HighLight>assets</HighLight>.
        </p>
      </div>
      <div className="empty-list__bottom-area">
        <p className="empty-list__bottom-area-text">
          An <HighLight>asset</HighLight> is more than a digital record of your IP.
        </p>
      </div>
    </div>
  )
}

const HighLight = bem.span('hightlight')
