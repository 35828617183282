import { ETHAddress } from 'original-works-core/models'

import ikuinenIcon from 'src/assets/teosto/ikuinen.png'
import sinaIcon from 'src/assets/teosto/sina.png'
import vuosienIcon from 'src/assets/teosto/vuosien.png'

const AGREEMENT_COVERS: Record<ETHAddress, string | undefined> = {
  // PRODUCTION
  '0x01b3c9bb60e61e8013baeefcb70d99f8c6c0c35f': ikuinenIcon,
  '0x25ac247c203505aba1668180ee6eb6819d0177c1': sinaIcon,
  '0xeda83b3351970e15f468819f17865fe6c36e9af7': vuosienIcon,

  // DEVELOPMENT
  '0x7e991111eb20692c2f14ff0c17fd1844cd5b2dd6': ikuinenIcon,
  '0x5ddc9b8153837bfd8241b8d36c5afb0d2d7b86ef': sinaIcon,
}

const PLACEHOLDER_COVER = 'https://i.imgur.com/BON28ZT.jpg'

export const getCover = (address: ETHAddress) => address && (AGREEMENT_COVERS[address] || PLACEHOLDER_COVER)
