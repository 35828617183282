import React from 'react'
import styled from 'styled-components'

import { H7, H4, H8, H6 } from 'src/ui/common'
import { COLORS } from 'src/ui/colors'
import ownIcon from 'src/assets/own-coin.svg'
import etherIcon from 'src/assets/ether.png'
import bnbIcon from 'src/assets/bnb-currency.png'
import profileDefaultIcon from 'src/assets/icons/profile.png'
import { useObservable, useServices, useAsync } from 'src/ui/hooks'
import { shorten } from 'src/ui/utils'

export default function Wallet () {
  const { balanceService, accountService, api } = useServices()
  const [balance] = useObservable(() => balanceService.balance, [])
  const [profile] = useAsync(() => api.getUserDetails(accountService.getAddress()), [])

  return (
    <Container>
      <ProfileBlock>
        <Avatar src={profile?.imageURL || profileDefaultIcon} />
        <H4 bold block>{profile && profile.name}</H4>
        <H7 gray block>{shorten(accountService.getAddress())}</H7>
      </ProfileBlock>
      <H8 gray block>Current balance</H8>
      <Balance>{balance && balance.total.format()}</Balance>
      <H8 gray block>Available funds</H8>
      <Currencies>
        {balance && balance.currencies.map(currency => (
          <Currency key={currency.balance.ticker}>
            <CurrencyIcon src={CURRENCY_ICON[currency.balance.ticker]} />
            <H6>{CURRENCY_NAME[currency.balance.ticker]}</H6>
            <CurrencyValues>
              <H6>{currency.balanceInUSD.format()}</H6>
            </CurrencyValues>
            <CurrencyTooltip>
              <H8 style={{ color: COLORS.white }}>{currency.balance.format()}</H8>
            </CurrencyTooltip>
          </Currency>
        ))}
      </Currencies>
    </Container>
  )
}

const CURRENCY_NAME = {
  ETH: 'Ether',
  OWN: 'OWN Coin',
  BNB: 'Binance Coin',
}

const CURRENCY_ICON = {
  ETH: etherIcon,
  OWN: ownIcon,
  BNB: bnbIcon,
}

const Container = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 20px 30px 40px 30px;
  align-self: flex-start;
`

const ProfileBlock = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "avatar name"
    "avatar address";
  grid-column-gap: 10px;
  align-items: flex-end;
  padding-bottom: 20px;
  border-bottom: 1px solid ${COLORS.dividerGray};
  margin-bottom: 20px;
`

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  grid-area: avatar;
`

const Balance = styled.p`
  margin-top: 13px;
  margin-bottom: 30px;
  font-size: 36px;
  line-height: 36px;
  color: ${COLORS.lightBlue};

  ::after {
    content: "USD";
    font-size: 14px;
    line-height: 20px;
    margin-left: 8px;
  }
`

const Currencies = styled.div`
  margin-top: 10px;
`

const CurrencyValues = styled.div`
  text-align: right;
`

const Currency = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 8px;
  border-top: 1px solid ${COLORS.dividerGray};
  padding: 8px 0px;
  align-items: center;
  position: relative;

  :last-child {
    border-bottom: 1px solid ${COLORS.dividerGray};
  }
`

const CurrencyTooltip = styled.div`
  position: absolute;
  border-radius: 8px;
  background-color: ${COLORS.black};
  z-index: 1;
  padding: 4px 12px;
  right: 0px;
  top: -15px;

  ${Currency}:not(:hover) & {
    display: none;
  }
`

const CurrencyIcon = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 50%;
`
