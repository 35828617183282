import React from 'react'

export function GridLines () {
  return (
    <div className="eh-grid-lines">
      <div className="eh-grid-lines__line" />
      <div className="eh-grid-lines__line" />
      <div className="eh-grid-lines__line" />
      <div className="eh-grid-lines__line" />
    </div>
  )
}
