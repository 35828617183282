import { Observable } from 'rxjs'

import { ApiAgreementContract } from 'original-works-core/models'
import { filterByTitle } from 'original-works-core/utils'
import { AgreementQueryService } from 'original-works-core/services'

export class AgreementListService {
  listItems: Observable<ApiAgreementContract[] | undefined>

  constructor (
    private agreementQueryService: AgreementQueryService,
  ) {
    this.listItems = this.agreementQueryService.agreements
  }

  search (searchString: string) {
    const items = this.agreementQueryService.getAgreements()
    if (items === undefined) return undefined

    return items.filter(filterByTitle(searchString))
  }
}
