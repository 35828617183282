import React, { useState } from 'react'

import cx from 'classnames'
import qrCodeIcon from 'src/assets/icons/qr-code.svg'
import { shorten } from 'src/ui/utils'
import { QRCodeModal } from './QRCodeModal'
import { useConfig } from 'src/ui/hooks'

export interface HeroTopProps {
  address: string,
  title: string,
  note: string,
  isAsset?: boolean,
}

export function HeroTop ({ address, title, note, isAsset }: HeroTopProps) {
  const [modalVisible, setModalVisible] = useState(false)
  return (
    <div
      className="hero-top"
      onClick={() => setModalVisible(true)}
    >
      <div className="hero-top__address-section">
        <img src={qrCodeIcon} alt="icon" className="hero-top__icon"/>
        <span className="hero-top__address">{shorten(address)}</span>
        <div className="hero-top__field">
          <span className="hero-top__description">{note}</span>
          <button className="hero-top__show-qr-code">Show QR Code</button>
        </div>
        <QRCodeModal
          address={address}
          title={title}
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          isAsset={isAsset}
        />
      </div>
      <TestnetPill />
    </div>
  )
}

function TestnetPill () {
  const { network } = useConfig()
  if (network === 'mainnet') {
    return null
  }
  return (
    <div className={cx(
      'hero-top-testnet-pill',
      `hero-top-testnet-pill--${network}`,
    )}>
      <div className="hero-top-testnet-pill__dot"/>
      <span className="hero-top-testnet-pill__text">Registered on <strong>Testnet</strong></span>
    </div>
  )
}
