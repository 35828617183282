import { getDefaultProvider } from 'ethers'

import { Config } from './Config'

export function getBinanceConfig (): Config {
  return {
    apiUrl: 'https://binance-api.original.works',
    network: 'binance',
    provider: getDefaultProvider('https://bsc-dataseed.binance.org'),
    nativeCoin: 'BNB',
    OWNTokenAddress: '0x554e05481c324C9Ff06c583CA8d6823f7a24ac23',
    cashoutAddress: '0x43f15e5539eC4B4394f7897F35975C98Ff67DFD4',
    factoryAddress: '0x4bCBC2665cD4e20dCDff76d73Bc788D5C8D2244F',
    feeManagerAddress: '0x66c2CD617928f976204f05b43047d509b9Ef4460',
    stripeKey: 'pk_test_4DmGPmpaGOHr92X4uL2IxoKc',

    // do not use values below

    campaignId: -1,
    campaignApiKey: '',
  }
}
