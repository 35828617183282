import React from 'react'

function CashoutsTableHead () {
  return (
    <thead className="cashouts-table__head">
      <tr>
        <th>Date</th>
        <th>Cashout Method</th>
        <th>Value</th>
        <th>Resolved</th>
        <th>Mark Resolved</th>
      </tr>
    </thead>
  )
}

export default CashoutsTableHead
