import React, { useState } from 'react'

const DESCRIPTION_SIZE_LIMIT = 250

export interface DescriptionProps {
  text: string,
}

export function Description ({ text }: DescriptionProps) {
  const [expanded, setExpanded] = useState(text.length <= DESCRIPTION_SIZE_LIMIT)

  return (
    <p className ="agreement-hero__description">
      <span>{expanded ? text : text.substring(0, DESCRIPTION_SIZE_LIMIT) + '...'}</span>
      {text.length > DESCRIPTION_SIZE_LIMIT && (
        <button className="agreement-hero__description-more" onClick={() => setExpanded(!expanded)}>
          {expanded ? 'Read less' : 'Read more'}
        </button>
      )}
    </p>
  )
}
