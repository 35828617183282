/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Observable } from 'rxjs'

export function useObservable<T> (
  getObservable: () => Observable<T>,
  deps: readonly any[],
): [T | undefined, any] {
  const [data, setData] = useState<T | undefined>(undefined)
  const [error, setError] = useState(null)
  useEffect(() => {
    const subscription = getObservable().subscribe(setData, setError)
    return () => subscription.unsubscribe()
  }, deps)
  return [data, error]
}
