import React, { useRef, useEffect, ChangeEvent, RefObject } from 'react'

import { formatNumber } from 'original-works-core/utils'
import { ApiAgreement } from 'original-works-core/models'

import { Modal } from 'src/ui/common'

export interface PayFormProps {
  agreement: ApiAgreement,
  value: string,
  onChange: (value: string) => void,
  onClose: () => void,
  onSubmit: () => void,
}

interface InputFieldProps {
  input: RefObject<HTMLInputElement>,
  value: string,
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void,
}

interface AgreementFieldProps {
  title: string,
}

const NUMBER_REGEX = /^(\d+(\.\d{0,2})?)?$/

export function PayForm ({ agreement, value, onChange, onClose, onSubmit }: PayFormProps) {
  const input = useRef<HTMLInputElement>(null)

  useEffect(() => input.current!.focus(), [])

  function onInputChange (e: ChangeEvent<HTMLInputElement>) {
    const value = (e.target.value || '').replace(/\$|,/g, '')
    if (NUMBER_REGEX.test(value)) {
      onChange(value)
    }
  }

  return (
    <Modal onClose={onClose} className="pay-form">
      <span className="pay-form__title">Pay Royalty</span>
      <InputField input={input} value={value} onInputChange={onInputChange}/>
      <AgreementField title={agreement.title}/>
      <div className="pay-form-footer">
        <span className="pay-form-footer__balance">{formatNumber(value) || 0} OWN</span>
        <span className="pay-form-footer__description">USD/OWN $1.00</span>
      </div>
      <div className="pay-form__button-wrapper">
        <button className="button" onClick={onSubmit} disabled={value === ''}>Pay now ⟶</button>
      </div>
    </Modal>
  )
}

function formatValue (value: string) {
  const formatted = formatNumber(value)
  return formatted && `$${formatted}`
}

function InputField ({ input, value, onInputChange }: InputFieldProps) {
  return (
    <div className="pay-form-field">
      <span className="pay-form-field__title">Amount</span>
      <div className="pay-form-input__wrapper">
        <input
          className="pay-form-input"
          ref={input}
          placeholder="$0"
          value={formatValue(value)}
          onChange={onInputChange}
        />
        <span className="pay-form-input__usd-ticker">USD</span>
      </div>
    </div>
  )
}

function AgreementField ({ title }: AgreementFieldProps) {
  return (
    <div className="pay-form-field">
      <span className="pay-form-field__title">To agreement</span>
      <span className="pay-form-agreement-name">{title}</span>
    </div>
  )
}
