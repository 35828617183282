import React from 'react'

import { NavBar, Footer } from 'src/ui/common'
import { useRedirectHome } from 'src/ui/hooks'

export function PageNotFound () {
  const goHome = useRedirectHome()

  return (
    <div className="page">
      <NavBar className="beige" noLinks />
      <div className="page-not-found">
        <p className="page-not-found__main-text">Error: 404</p>
        <p className="page-not-found__main-text--highlight">This is not the page you’re looking for.</p>
        <p className="page-not-found__note">
          We’ll try to automatically redirect you in a few seconds, or you can hit the button below to return home.
        </p>
        <button className="button page-not-found__home-button" onClick={goHome}>Go home</button>
      </div>
      <Footer />
    </div>
  )
}
