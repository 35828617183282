import React from 'react'
import styled from 'styled-components'

import { QR_DATA_MOCKS } from './mocks'
import { AgreementQR } from './AgreementQR'
import { AdminPage } from '../AdminPage'

export const PartnerAgreements = () => {
  const dataSet = QR_DATA_MOCKS.map(elem => JSON.stringify(elem))

  return (
    <AdminPage>
      <Container>
        {dataSet.map((data, index) => (
          <ItemWrapper key={index}>
            <AgreementQR data={data} />
            <Link href={QR_DATA_MOCKS[index].fs}>{QR_DATA_MOCKS[index].fs}</Link>
          </ItemWrapper>
        ))}
      </Container>
    </AdminPage>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`
const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 50%;
  margin: 20px 0;
`
const Link = styled.a`
  display: block;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
`
