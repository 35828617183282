import React from 'react'
import cx from 'classnames'

export interface LoadMoreButtonProps {
  hidden: boolean,
  onClick: () => void,
}

export function LoadMoreButton ({ hidden, onClick }: LoadMoreButtonProps) {
  return (
    <button
      className={cx('load-more-button', hidden && 'load-more-button--hidden')}
      onClick={onClick}
    >
      Load more
    </button>
  )
}
