import React from 'react'
import { CashoutService, Cashout } from 'src/services/CashoutService'

interface CashoutsTableRowProps {
  cashout: Cashout,
  cashoutService: CashoutService,
  onMarkResolved (): void,
}

interface CashoutsTableRowState {
  value?: string,
  error?: boolean,
}

class CashoutsTableRow extends React.Component<CashoutsTableRowProps, CashoutsTableRowState> {
  constructor (props: CashoutsTableRowProps) {
    super(props)
    this.state = {
      value: '...',
    }
  }

  async componentDidMount () {
    const { cashoutService, cashout } = this.props
    const { value, error } = await cashoutService
      .getTransactionDetails(cashout.transactionHash)
    this.setState({ value, error })
  }

  render () {
    const { cashout, onMarkResolved } = this.props
    const { value, error } = this.state

    const etherscanLink = `https://kovan.etherscan.io/tx/${cashout.transactionHash}`
    return (
      <tr key={cashout.transactionHash} className="cashouts-table__row">
        <td>
          {cashout.createdAt}{' '}
          <a href={etherscanLink} target="_blank">🔗</a>{/* eslint-disable-line */}
        </td>
        <td>{cashout.type} - {cashout.paypalAddress}</td>
        <td>{error ? 'Error' : `${value} OWN`}</td>
        <td>{cashout.resolved ? '✅' : '❌'}</td>
        <td>
          {!cashout.resolved && (
            <button
              type="button"
              className="cashout-resolve-button"
              onClick={onMarkResolved}
            >
              Mark Resolved
            </button>
          )}
        </td>
      </tr>
    )
  }
}

export default CashoutsTableRow
