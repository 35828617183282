import { useEffect } from 'react'

export function useRedirectHome () {
  useEffect(() => {
    const timeoutID = setTimeout(() => goHome(), 10000)

    return () => clearTimeout(timeoutID)
  }, [])

  function goHome () {
    window.location.assign('https://original.works')
  }

  return goHome
}
