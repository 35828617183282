import React from 'react'
import { EarningsChartData, Period } from 'original-works-core/utils'

export interface XAxisLabelsProps {
  period: Period,
  chartData: EarningsChartData,
}

export function XAxisLabels ({ period, chartData }: XAxisLabelsProps) {
  const labels = getLabels(period, chartData)
  return (
    <div className="eh-x-axis-labels">
      {labels.map((label, index) => (
        <div key={index} className="eh-x-axis-labels__label">
          {label}
        </div>
      ))}
    </div>
  )
}

function getLabels (period: Period, chartData: EarningsChartData) {
  if (period === 'daily') {
    return chartData.daily.map(
      x => x.date.format('D') + '\n' + x.date.format('dddd').substring(0, 3),
    )
  } else {
    return chartData.monthly.map(
      x => x.date.format('MMM'),
    )
  }
}
