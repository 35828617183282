export class PersistentStorage {
  setItem (key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  getItem (key: string) {
    const value = localStorage.getItem(key)
    if (value !== null) {
      try {
        return JSON.parse(value)
      } catch {
        localStorage.removeItem(key)
      }
    }
    return null
  }

  removeItem (key: string) {
    localStorage.removeItem(key)
  }
}
