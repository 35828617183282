import { Api } from './Api'

export class UserService {
  constructor (
    private api: Api,
  ) {}

  async getUser (address: string) {
    return this.api.getUserDetails(address)
  }

  async getUserByPaymentId (id: string) {
    return this.api.getUserByPaymentId(id)
  }

  async isPaymentEnabled (id: string) {
    return this.api.isPaymentEnabled(id)
  }
}
