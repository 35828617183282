import styled from 'styled-components'

export const Button = styled.button`
  background-color: white;
  border: 1px solid gray;
  padding: 8px;
  font-size: inherit;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
`
