import React from 'react'
import { Period } from 'original-works-core/utils'

export interface MapKeyBarProps {
  period: Period,
}

export function MapKeyBar ({ period }: MapKeyBarProps) {
  return (
    <div className="eh-map-key-bar">
      <div className="eh-map-key-bar__field">
        <div className="eh-map-key-bar__dot-light" />
        <span className="eh-map-key-bar__text">{period === 'daily' ? 'Pipeline' : 'Earnings'}</span>
      </div>
      <div className="eh-map-key-bar__field">
        <div className="eh-map-key-bar__dot-dark" />
        <span className="eh-map-key-bar__text">Received</span>
      </div>
    </div>
  )
}
