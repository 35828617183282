import React from 'react'
import { Wallet } from 'ethers'

import { prepareSignedRequest } from 'original-works-core/utils'

import { useServices } from 'src/ui/hooks'
import config from 'src/config'

export interface DevSignInButtonProps {
  sessionId: string,
}

export function DevSignInButton ({ sessionId }: DevSignInButtonProps) {
  const { accountService } = useServices()

  if (process.env.NODE_ENV !== 'development') {
    return null
  }

  async function handleClick () {
    const wallet = Wallet.createRandom()
    await simulateQRCodeScan(wallet, sessionId)
    accountService.signIn(await wallet.getAddress(), true)
  }

  return (
    <div className="dev-sign-in-button__field">
      <button
        className="button"
        onClick={handleClick}
      >Dev Sign In</button>
    </div>
  )
}

export async function simulateQRCodeScan (wallet: Wallet, sessionId: string) {
  const url = `${config.apiUrl}/session-connections/${sessionId}`
  const options = await prepareSignedRequest(
    wallet,
    url,
    'PUT',
    Date.now(),
    { address: await wallet.getAddress() },
  )
  await fetch(url, options)
}
