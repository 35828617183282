import Big, { BigSource } from 'big.js'

const safeBig = (a?: BigSource) => new Big(a || '0')
const ROUND_DOWN = 0

export const percentageOf = (total: BigSource, value: BigSource) => safeBig(value)
  .div(safeBig(total))
  .times(100)
  .toFixed(0)

export const isEqual = (a: BigSource, b: BigSource) => safeBig(a).eq(safeBig(b))

export const sum = (values: BigSource[]) => values
  .reduce((a, b) => safeBig(a).plus(safeBig(b)))
  .toString()

export const isGreaterThan = (a: BigSource, b: BigSource) => safeBig(a).gt(safeBig(b))

export const distribute = (total: BigSource, holdersCount: number) => {
  if (holdersCount === 1) {
    return [total.toString()]
  }

  const minimal = safeBig(total)
    .div(holdersCount)
    .round(0, ROUND_DOWN)
  const maximal = safeBig(total)
    .minus(minimal.times(holdersCount - 1))

  const result = []
  result.push(maximal.toString())
  for (let i = 1; i < holdersCount; i += 1) {
    result.push(minimal.toString())
  }

  return result
}
