import React from 'react'
import styled from 'styled-components'

import { CurrencyValue } from 'original-works-core/utils'

import { H8, H6 } from 'src/ui/common'
import { formatTimestamp } from 'src/ui/utils'
import imgReceive from 'src/assets/received.png'
import { useServices, useAsync } from 'src/ui/hooks'

export interface ActivityListItemProps {
  timestamp: number,
  value: CurrencyValue<'OWN'>,
  agreementName: string,
  from: string,
  agreementOwner: string,
  isPending?: boolean,
}

export const ActivityListItem = ({
  timestamp,
  value,
  agreementName,
  isPending,
  from,
  agreementOwner,
}: ActivityListItemProps) => {
  const { userService } = useServices()
  const [user] = useAsync(() => userService.getUser(from), [])
  const { exchangeRateService } = useServices()
  const usdValue = exchangeRateService.toUSD(value)

  function getUserName () {
    return agreementOwner.toLowerCase() === from.toLowerCase() ? 'You' : user && user.name
  }

  return (
    <Container>
      <H8 gray>{formatTimestamp(timestamp)}</H8>
      <Image src={imgReceive} alt="Receive" />
      <div>
        <H6>{getUserName()} paid</H6>
        <H6 blue> {usdValue.format()} </H6>
        <LightText>({value.format()}) </LightText>
        <H6>to</H6>
        <H6 bold> {agreementName}</H6>
      </div>
      <BlueText>{isPending ? 'Pending' : ''}</BlueText>
    </Container>
  )
}

const Container = styled.div`
  padding: 0 30px 0 12px;
  display: grid;
  grid-template-columns: 100px auto 4fr 80px;
  grid-column-gap: 10px;
  border-radius: 8px;
  background-color: #ffffff;
  height: 64px;
  align-items: center;
`

const LightText = styled(H6).attrs({ gray: true })`
  font-size: 14px;
`

const Image = styled.img`
  height: 40px;
  width:40px;
`

const BlueText = styled.p`
  color: #7341f3;
  display:inline;
`
