import React from 'react'
import dayjs from 'dayjs'

import { ApiAgreementContract } from 'original-works-core/models'
import { PayoutActivity } from 'original-works-core/services'

import { ProfileImage } from 'src/ui/common'
import { useAsync, useServices } from 'src/ui/hooks'
import { Description } from './Description'
import { HeroFooter, HeroTop } from '../../Components'

export interface AgreementHeroProps {
  contract: ApiAgreementContract,
  payouts?: PayoutActivity[],
  isAllowedToView?: boolean,
}

export function AgreementHero ({ isAllowedToView, contract, payouts }: AgreementHeroProps) {
  const { api } = useServices()
  const [profile] = useAsync(() => api.getUserDetails(contract.holders[0].address), [contract])
  const { accountService } = useServices()

  return (
    <div className="agreement-hero">
      <div className="agreement-hero__content">
        <HeroTop address={contract.address} title={contract.title} note="Agreement address"/>
        <h1 className="agreement-hero__title">{contract.title}</h1>
        <div className="agreement-hero-user">
          <ProfileImage
            src={profile?.imageURL}
            className="agreement-hero-user__avatar"
          />
          <span className="agreement-hero-user__name">{profile && profile.name}</span>
          <span className="agreement-hero-user__time">
            Created {dayjs(contract.timestamp * 1000).format('DD MMM YYYY')}
          </span>
        </div>
        <Description text={contract.description} />
        <HeroFooter
          isAllowedToView={isAllowedToView}
          payouts={payouts}
          isSignedIn={accountService.isSignedIn()}
        />
      </div>
    </div>
  )
}
