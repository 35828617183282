import React from 'react'
import styled from 'styled-components'

import { CurrencyValue } from 'original-works-core/utils'

import { useServices } from 'src/ui/hooks'
import { H7, H8 } from 'src/ui/common'

export interface PayingViewProps {
  ownValue: CurrencyValue<'OWN'>,
  eurValue: CurrencyValue<'EUR'>,
  displayEUR?: boolean,
}

export function PayingView ({ ownValue, eurValue, displayEUR }: PayingViewProps) {
  const { exchangeRateService } = useServices()

  const ownRate = exchangeRateService.getExchangeRate('OWN')
  const eurRate = exchangeRateService.getExchangeRate('EUR')

  return (
    <SendingRow>
      <SendingRowPaying>Paying</SendingRowPaying>
      <H7>{ownValue.format()}</H7>
      <ExchangeRate>1 OWN = {ownRate.formatWithTicker()}</ExchangeRate>
      {displayEUR && (
        <>
          <H7>{eurValue.format()}</H7>
          <ExchangeRate>1 EUR = {eurRate.formatWithTicker()}</ExchangeRate>
        </>
      )}
    </SendingRow>
  )
}

const SendingRow = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 10px;
  margin-bottom: 15px;
  align-items: baseline;
`

const SendingRowPaying = styled(H7).attrs({ gray: true })`
  grid-row: span 2;
`

const ExchangeRate = styled(H8).attrs({ gray: true })`
  text-align: right;
`
