import React, { MouseEvent, ReactNode } from 'react'
import cx from 'classnames'

import { CloseButton } from './CloseButton'

export interface ModalProps {
  children: ReactNode,
  onClose: () => void,
  className?: string,
  noClose?: boolean,
  small?: boolean,
}

export function Modal ({ children, onClose, className, noClose, small }: ModalProps) {
  function onClick (e: MouseEvent) {
    if (e.target === e.currentTarget) {
      e.stopPropagation()
      onClose()
    }
  }

  return (
    <div className="modal__backdrop" onClick={onClick}>
      <div className={cx('modal', small && 'modal--small', className)}>
        {!noClose && <CloseButton onClick={onClick} />}
        {children}
      </div>
    </div>
  )
}
