import React from 'react'

import { formatNumber } from 'original-works-core/utils'
import {
  ApiActivityContractTransferReceived,
  ApiAgreementContract,
  ApiAssetContract,
  ApiContract,
  ApiUserData,
} from 'original-works-core/models'

import { useAsync, useServices } from 'src/ui/hooks'
import { ActivityModal } from './ActivityModal'
import { SharesField, ProfileField, AssetField, AgreementField, DetailsField } from './fields'

export interface SharesReceivedModalProps {
  isVisible: boolean,
  onClose: () => void,
  title: string,
  user?: ApiUserData,
  activity: ApiActivityContractTransferReceived,
}

export interface ContractFieldProps {
  contract: ApiContract,
  bottom?: boolean,
}

export function SharesReceivedModal ({ isVisible, onClose, title, activity, user }: SharesReceivedModalProps) {
  const { api } = useServices()
  const [contract] = useAsync(() => api.getContractDetails(activity.contract.address), [activity])
  const shares = formatNumber(activity.value) + (activity.value === '1' ? ' Share' : ' Shares')

  return (
    <ActivityModal
      title={title}
      isVisible={isVisible}
      onClose={onClose}
    >
      <SharesField
        value={activity.value}
        totalSupply={activity.contract.totalSupply.toString()}
      />
      {user
        ? <ProfileField title="From" profile={user} />
        : contract && <ContractField contract={contract} />
      }
      <DetailsField timestamp={activity.timestamp} value={shares} />
      {user && contract && (
        <ContractField contract={contract} bottom />
      )}
    </ActivityModal>
  )
}

function ContractField ({ contract, bottom }: ContractFieldProps) {
  return contract.type === 'ASSET'
    ? <AssetField contract={contract as ApiAssetContract} title="Of the asset" bottom={bottom} />
    : <AgreementField contract={contract as ApiAgreementContract} title="Of the agreement" bottom={bottom} />
}
