import React, { useState } from 'react'

import { Holder } from 'original-works-core/models'
import { percentageToBalance } from 'original-works-core/utils'

import { distribute, sum } from 'src/services/tokens'
import { Field } from 'src/ui/common'
import { Preview } from './Preview'
import { HoldersList, DistributeEvenly, TotalTokens, WalletAddressInput } from './holders'
import { Creator } from './Creator'

interface HoldersStepProps {
  title: string,
  description: string,
  totalSupply: string,
  holders: Holder[],
  error?: boolean,
  onChange (holders: Holder[]): void,
  onSubmit (): void,
  goBack (): void,
}

export function HoldersStep (props: HoldersStepProps) {
  const [distributeEvenly, setDistributeEvenly] = useState(true)

  function addHolder (address: string) {
    const newHolders = [
      ...props.holders,
      {
        address,
        balance: '0',
        isAdmin: false,
      },
    ]
    updateHolders(newHolders)
  }

  function removeHolder (address: string) {
    const updatedHolders = props.holders.filter(holder => holder.address !== address)
    updateHolders(updatedHolders)
  }

  function updateHolders (holders: Holder[]) {
    if (distributeEvenly) {
      props.onChange(evenlyDistributed(holders, props.totalSupply))
    } else {
      props.onChange(holders)
    }
  }

  function onAdminChange (index: number) {
    props.onChange(
      props.holders.map((holder, i) => (index === i
        ? { ...holder, isAdmin: !holder.isAdmin }
        : holder)),
    )
  }

  function onTokensChange (index: number, value: string) {
    if (value.match(/^\d*$/)) {
      setDistributeEvenly(false)
      props.onChange(
        props.holders.map((holder, i) => (index === i
          ? { ...holder, balance: value }
          : holder)),
      )
    }
  }

  function onPercentageChange (index: number, value: string) {
    onTokensChange(index, percentageToBalance(value || '0', props.totalSupply))
  }

  function onDistributeEvenlyChange (value: boolean) {
    setDistributeEvenly(value)
    if (value) {
      props.onChange(evenlyDistributed(
        props.holders,
        props.totalSupply,
      ))
    }
  }

  return (
    <Creator
      disabled={props.error}
      buttonContent="Create agreement"
      onSubmit={props.onSubmit}
      twoColumns
      disallowEnterSubmit
    >
      <Preview
        title={props.title}
        description={props.description}
        tokens={props.totalSupply}
        onClick={props.goBack}
      />
      <Field label="Who are the initial rights holders?">
        <WalletAddressInput
          holders={props.holders}
          onSubmit={addHolder}
        />
        <HoldersList
          tokens={props.totalSupply}
          holders={props.holders}
          onAdminChange={onAdminChange}
          onTokensChange={onTokensChange}
          onPercentageChange={onPercentageChange}
          onRemoveHolder={removeHolder}
        />
        <div className="create-agreement-footer">
          <DistributeEvenly
            onChange={(value) => onDistributeEvenlyChange(value)}
            value={distributeEvenly}
          />
          <TotalTokens
            totalSupply={props.totalSupply}
            sum={sum(props.holders.map(x => x.balance))}
          />
        </div>
      </Field>
    </Creator>
  )
}

function evenlyDistributed (holders: Holder[], totalSupply: string) {
  const balances = distribute(totalSupply, holders.length)
  return holders.map(
    (holder, i) => ({ ...holder, balance: balances[i] }),
  )
}
