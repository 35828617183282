import React from 'react'
import dayjs from 'dayjs'

import { formatNumber, toFormattedFixedPercentage } from 'original-works-core/utils'
import { ApiTransfer } from 'original-works-core/models'

import { UserProfile } from 'src/ui/common'

export interface TransferHistoryListItemProps {
  transfer: ApiTransfer,
  totalSupply: string,
}

export function TransferHistoryListItem ({ transfer, totalSupply }: TransferHistoryListItemProps) {
  return (
    <li className="transfer-history-list-item">
      <UserProfile userAddress={transfer.from}/>
      <div className="transfer-history-list-item__arrow">⟶</div>
      <UserProfile userAddress={transfer.to}/>
      <span className="transfer-history-list-item__date">{dayjs(transfer.timestamp * 1000).format('DD MMM YYYY')}</span>
      <div className="transfer-history-list-item__balance">
        <span className="transfer-history-list-item__shares">{formatNumber(transfer.value)} Shares</span>
        <span className="transfer-history-list-item__percentage">
          {toFormattedFixedPercentage(transfer.value, totalSupply)}
        </span>
      </div>
    </li>
  )
}
