import React from 'react'
import { NavBar, Spinner } from 'src/ui/common'
import { PageNotFound } from 'src/ui/PageNotFound'

export interface LoadingStateProps {
  error?: any,
}

export function LoadingState ({ error }: LoadingStateProps) {
  if (error) {
    return <PageNotFound />
  } else {
    return (
      <div className="page">
        <NavBar noLinks />
        <div className="page__centered-content">
          <Spinner />
        </div>
      </div>
    )
  }
}
