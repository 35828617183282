import React from 'react'

function getTabClassName (index: number, currentStep: number) {
  if (index === currentStep) {
    return 'tab-bar__item tab-bar__item--active'
  } if (index < currentStep) {
    return 'tab-bar__item tab-bar__item--completed'
  }
  return 'tab-bar__item'
}

interface TabBarProps {
  title: string,
  labels: string[],
  currentStep: number,
  onStepClick (step: number): void,
  onClose (): void,
}

export function TabBar (props: TabBarProps) {
  return (
    <div className="tab-bar">
      <p className="tab-bar__title">{props.title}</p>
      <ul className="tab-bar__items">
        {props.labels.map((label, index) => (
          <li
            key={index}
            className={getTabClassName(index, props.currentStep)}
          >
            <button
              type="button"
              onClick={() => props.onStepClick(index)}
              disabled={index >= props.currentStep}
            >
              {label}
            </button>
          </li>
        ))}
      </ul>
      <button type="button" onClick={props.onClose} className="tab-bar__close" />
    </div>
  )
}
