import React, { useState } from 'react'
import styled from 'styled-components'

import { QRCode } from '../common/QRCode'
import { useConfig, useServices } from '../hooks'
import { AdminPage } from './AdminPage'
import { Button } from './Button'

export function RedeemCodes () {
  const { redeemService } = useServices()
  const { network } = useConfig()
  const [redeemCode, setRedeemCode] = useState<string | undefined>(undefined)

  const [ETHValue, setETHValue] = useState('')
  const [OWNValue, setOWNValue] = useState('')

  async function generateRedeemCode (ETH: string, OWN: string) {
    const code = await redeemService.getRedeemCode(ETH || '0', OWN || '0')
    setRedeemCode(code)
  }

  return (
    <AdminPage>
      <Container>
        <h1>Redeem Codes</h1>
        <Wrapper>
          {network !== 'ownet' && (
            <InputWrapper>
              <label htmlFor="eth">Eth</label>
              <input
                type="number"
                placeholder="0.01"
                name="eth"
                value={ETHValue}
                onChange={(event) => setETHValue(event.target.value)}
              />
            </InputWrapper>
          )}
          <InputWrapper>
            <label htmlFor="own">Own</label>
            <input
              type="number"
              placeholder="0.01"
              name="own"
              value={OWNValue}
              onChange={(event) => setOWNValue(event.target.value)}
            />
          </InputWrapper>
        </Wrapper>
        <Button onClick={() => generateRedeemCode(ETHValue, OWNValue)}>
          Generate Redeem Code
        </Button>
        {redeemCode && <QRCode data={redeemCode} />}
        {redeemCode && <p>{redeemCode}</p>}
      </Container>
    </AdminPage>
  )
}

const Container = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 16px;

  h1 {
    font-size: 2em;
  }

  & > * {
    margin-bottom: 16px;
  }

  p {
    font-weight: 500;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 5px;
`
