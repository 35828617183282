import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Redirect } from 'react-router-dom'

import { useServices, useAsync } from '../hooks'
import { LoadingState } from './LoadingState'

export interface ContractScreenProps {
  address: string,
}

export function ContractScreen (props: RouteComponentProps<ContractScreenProps>) {
  const { address } = props.match.params
  const { api } = useServices()
  const [contract, error] = useAsync(() => api.getContractDetails(address), [address])

  if (contract && contract.type === 'AGREEMENT') {
    return <Redirect to={`/agreement/${address}`} />
  } else if (contract && contract.type === 'ASSET') {
    return <Redirect to={`/asset/${address}`} />
  } else {
    return <LoadingState error={error} />
  }
}
