import React from 'react'

import { ApiUserData } from 'original-works-core/models'

import { ProfileImage } from 'src/ui/common'

export interface ProfileFieldProps {
  profile?: ApiUserData,
  title: string,
}

export function ProfileField ({ profile, title }: ProfileFieldProps) {
  return (
    <div className="activity-modal-field">
      <p className="activity-modal-field__title">{title}</p>
      <div className="activity-modal-profile__wrapper">
        <ProfileImage src={profile?.imageURL} />
        <p className="activity-modal-profile__name">{profile && profile.name}</p>
      </div>
    </div>
  )
}
