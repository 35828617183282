import './styles/main.scss'
import React from 'react'
import { render } from 'react-dom'

import config from './config'
import { createServices } from './services'
import { ConfigContext } from './ui/hooks/useConfig'
import { ServiceContext } from './ui/hooks/useServices'
import App from './ui/App'

const services = createServices(config)

render(
  <ConfigContext.Provider value={config}>
    <ServiceContext.Provider value={services}>
      <App />
    </ServiceContext.Provider>
  </ConfigContext.Provider>,
  document.getElementById('app'),
)
