import React, { useState } from 'react'
import cx from 'classnames'

import searchIcon from 'src/assets/icons/search--black.svg'

export interface SearchBarProps {
  searchString: string,
  onSearch: (searchString: string) => void,
}

export const SearchBar = React.forwardRef((
  { searchString, onSearch }: SearchBarProps,
  ref: React.Ref<HTMLInputElement>,
) => {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <div className={cx('search-bar__wrapper', isFocused && 'active')}>
      <img src={searchIcon} alt="search" className="search-bar__icon" />
      <input
        type="text"
        className="search-bar__input"
        placeholder="Search"
        value={searchString}
        onChange={(e) => onSearch(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        ref={ref}
      />
    </div>
  )
})
