// TODO: get rid off
import { Api } from '../Api'

export class AgreementDetailsService {
  constructor (
    private api: Api,
  ) {}

  async getAgreementDetails (address: string) {
    return this.api.getAgreementDetails(address)
  }
}
