import React, { ReactNode } from 'react'
import { NavLink } from 'react-router-dom'

export interface NavBarTabProps {
  to: string,
  children: ReactNode,
  disabled?: boolean,
}

export function NavBarTab ({ to, children, disabled }: NavBarTabProps) {
  return disabled
    ? <p className="navbar__tab">{children}</p>
    : (
      <NavLink
        to={to}
        className="navbar__tab"
        activeClassName="navbar__tab--active"
        exact
      >
        {children}
      </NavLink>
    )
}
