import React from 'react'

import { CurrencyValue, formatNumber } from 'original-works-core/utils'

export interface AmountFieldProps {
  value: CurrencyValue<'OWN'>,
}

export function AmountField ({ value }: AmountFieldProps) {
  return (
    <div className="activity-modal-field">
      <p className="activity-modal-field__title">Amount</p>
      <div className="activity-modal-amount__wrapper">
        <p className="activity-modal-amount">+${formatNumber(value.value.toString())}</p>
        <p className="activity-modal-amount__usd-ticker">USD</p>
      </div>
    </div>
  )
}
