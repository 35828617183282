import React from 'react'
import dayjs from 'dayjs'

export interface DetailsFieldProps {
  value?: string,
  timestamp: number,
}

export function DetailsField ({ value, timestamp }: DetailsFieldProps) {
  return (
    <div className="activity-modal-details">
      {value && (
        <div className="activity-modal-details__balance">
          <p className="activity-modal-details__value">{value}</p>
          <p className="activity-modal-details__received">received</p>
        </div>
      )}
      <p className="activity-modal-details__confirmation">
        Confirmed on {dayjs(timestamp * 1000).format('DD MMM YYYY, hh:mma')}
      </p>
    </div>
  )
}
