import { PayoutActivity } from 'original-works-core/services'
import { CurrencyValue } from 'original-works-core/utils'
import dayjs from 'dayjs'

const ZERO = new CurrencyValue(0, 'USD')

export function getLastPayment (payouts?: PayoutActivity[]): { amount: string, date: string } {
  if (!payouts) {
    return {
      amount: '...',
      date: '...',
    }
  } else if (payouts.length === 0) {
    return {
      amount: '$' + ZERO.formatWithTicker(),
      date: 'Never',
    }
  } else {
    const item = [...payouts].sort((a, b) => b.timestamp - a.timestamp)[0]
    return {
      amount: '$' + new CurrencyValue(item.value.value, 'USD').formatWithTicker(),
      date: dayjs(item.timestamp * 1000).format('D MMMM YYYY'),
    }
  }
}

export function getLifetimeEarnings (payouts?: PayoutActivity[]): string {
  if (!payouts) {
    return '...'
  } else if (payouts.length === 0) {
    return '$' + ZERO.formatWithTicker()
  } else {
    return '$' + new CurrencyValue(
      payouts.reduce(
        (acc, cur) => cur.value.add(acc),
        new CurrencyValue(0, 'OWN'),
      ).value, 'USD')
      .formatWithTicker()
  }
}
