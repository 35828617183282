import React from 'react'

export interface YAxisLabelsProps {
  maxChartValue: number,
}

export function YAxisLabels ({ maxChartValue }: YAxisLabelsProps) {
  const labels = getLabels(maxChartValue)

  return (
    <div className="eh-y-axis-labels">
      {labels.map(label => (
        <span key={labels.indexOf(label)} className="eh-y-axis-labels__label">{label}</span>
      ))}
    </div>
  )
}

function getLabels (maxChartValue: number) {
  const chartStep = maxChartValue / 4
  return ['$0', `$${chartStep}`, `$${chartStep * 2}`, `$${chartStep * 3}`, `$${chartStep * 4}`]
}
