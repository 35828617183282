import React from 'react'
import { ContractListPage } from './ContractListPage'
import { EmptyAgreements } from './EmptyAgreements'
import { EmptyAssets } from './EmptyAssets'

export function AgreementsListPage () {
  return (
    <ContractListPage
      type="AGREEMENT"
      title="Agreements"
      empty={EmptyAgreements}
    />
  )
}

export function AssetsListPage () {
  return (
    <ContractListPage
      type="ASSET"
      title="Assets"
      empty={EmptyAssets}
    />
  )
}
