import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import { ApiAssetContract, Factsheet, FieldList } from 'original-works-core/models'
import { toEarningsSummaryData } from 'original-works-core/utils'

import agreementIcon from 'src/assets/icons/agreement.svg'
import { songIcon, recordingIcon, releaseIcon } from 'src/assets/icons/assetContractIcons'
import options from 'src/assets/icons/more.svg'
import { ContractIcon } from 'src/ui/common'
import { OptionsMenu } from 'src/ui/Contracts/OptionsMenu'
import { useAsync, useServices } from 'src/ui/hooks'

import { OwnerInfo } from './OwnerInfo'
import { capitalize, getUserShares } from './utils'

export interface AssetListItemProps {
  asset: ApiAssetContract,
  onlyShared: boolean,
  user: string,
}

const MIDDOT_WITH_SPACES = ' \u00B7 '

export function AssetListItem ({ asset, onlyShared, user }: AssetListItemProps) {
  const { api, payoutQueryService } = useServices()

  const { factsheet } = asset
  const subtitle = [capitalize(factsheet.type), factsheet.subtitle].join(MIDDOT_WITH_SPACES)
  const shares = getUserShares(asset.holders, user, asset.totalSupply)
  const [payouts] = useAsync(() => payoutQueryService.getPayouts(asset.address), [])
  const [financials] = useAsync(() => api.getContractFinancials(asset.address), [])
  const pipelineRevenue = useMemo(
    () => toEarningsSummaryData(payouts?.activity, financials).earned,
    [payouts, financials],
  )

  return (
    <Link
      className={cx('contracts-list__item', 'assets-list__item', onlyShared && 'assets-list__item--only-shared')}
      to={`/asset/${asset.address}`}
    >
      <div className="contracts-list__item__main-data">
        <ContractIcon
          src={getAssetIcon(factsheet)}
          className="contracts-list__item-icon"
        />
        <div className="contracts-list__item__main-data-text-wrapper">
          <p className="contracts-list__item-title">{factsheet.title}</p>
          <p className="contracts-list__item-subtitle">{subtitle}</p>
        </div>
      </div>
      <p className="contracts-list__item-identifier">{getAssetIdentifier(factsheet)}</p>
      {onlyShared && <OwnerInfo address={asset.holders[0].address} />}
      <p className="contracts-list__item-shares">{shares}</p>
      <p className="contracts-list__item-revenue">
        {pipelineRevenue.last30Days?.formatWithSign() || '$0.00'}
      </p>
      <div className="contracts-list__item-options">
        <img src={options} alt="options"/>
        <OptionsMenu contractAddress={asset.address} />
      </div>
    </Link>
  )
}

function getAssetIdentifier (factsheet: Factsheet) {
  const mainData = factsheet.details[0] as FieldList

  if (!mainData) {
    return '-'
  }

  function findIdentifier (identifierName: string) {
    return mainData.items.find(item => item.title === identifierName)?.value || '-'
  }

  switch (factsheet.type.toLowerCase()) {
    case 'release':
      return findIdentifier('UPC')
    case 'song':
      return findIdentifier('ISWC')
    case 'recording':
      return findIdentifier('ISRC')
    default:
      return '-'
  }
}

function getAssetIcon (factsheet: Factsheet) {
  if (factsheet.artwork) {
    return factsheet.artwork
  }

  switch (factsheet.type.toLowerCase()) {
    case 'song':
      return songIcon
    case 'recording':
      return recordingIcon
    case 'release':
      return releaseIcon
    default:
      return agreementIcon
  }
}
