import React, { useState, ComponentType, useRef } from 'react'

import { useObservable, useServices } from 'src/ui/hooks'
import { NavBar, Footer, SearchBar } from 'src/ui/common'
import { ContractList } from './ContractList'
import { ListNavigation } from './ListNavigation'
import { ListNavigationItem } from './ListNavigationItem'

export interface ContractListPageProps {
  title: string,
  type: 'ASSET' | 'AGREEMENT',
  empty: ComponentType,
}

export function ContractListPage ({ title, type, empty }: ContractListPageProps) {
  const [onlyShared, setOnlyShared] = useState(false)
  const [searchString, setSearchString] = useState('')

  const { contractQueryService, accountService } = useServices()
  const user = accountService.getAddress()
  let [contracts, error] = useObservable(() => contractQueryService.contracts, [])
  const emptyList = !error && contracts?.length === 0

  contracts = contracts?.filter(contract => contract.type === type)
  if (searchString) {
    contracts = contracts?.filter(contract => {
      const title = contract.type === 'AGREEMENT'
        ? contract.title
        : contract.factsheet.title
      return title.toLowerCase().includes(searchString.toLowerCase())
    })
  }
  if (onlyShared) {
    contracts = contracts?.filter(contract => contract.holders[0].address !== user)
  }

  const searchInput = useRef<HTMLInputElement>(null)

  function activateSearch () {
    searchInput.current?.focus({ preventScroll: true })
  }

  const Empty = empty
  return (
    <div className="page">
      <NavBar className={emptyList ? 'white' : undefined} />
      {emptyList && <Empty />}
      {!emptyList && (
        <div className="contracts-list__wrapper">
          <ListNavigation title={title} onSearchClick={activateSearch}>
            <ListNavigationItem
              active={!onlyShared}
              onClick={() => setOnlyShared(false)}
            >
              Recent
            </ListNavigationItem>
            <ListNavigationItem
              active={onlyShared}
              onClick={() => setOnlyShared(true)}
            >
              Shared with me
            </ListNavigationItem>
          </ListNavigation>
          <div>
            <div className="contracts-list__top-area">
              <SearchBar
                searchString={searchString}
                onSearch={setSearchString}
                ref={searchInput}
              />
            </div>
            {error && <p>{JSON.stringify(error)}</p>}
            {!error && (
              <ContractList
                contracts={contracts || []}
                onlyShared={onlyShared}
                type={type}
              />
            )}
          </div>
        </div>
      )}
      <Footer />
    </div>
  )
}
