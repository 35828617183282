import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Redirect } from 'react-router-dom'

import { NavBar, Footer, BottomPanel } from '../common'
import { useServices, useAsync } from '../hooks'
import { LoadingState } from './LoadingState'
import { getFakePayouts, checkIfAllowedToView } from './utils'
import {
  AgreementHero,
  PaySection,
  EarningsHistory,
  Activity,
  RightsHolders,
  TransferHistory,
} from './Sections'

export interface AgreementScreenProps {
  address: string,
}

export function AgreementScreen (props: RouteComponentProps<AgreementScreenProps>) {
  const { address } = props.match.params

  const { api, payoutQueryService, accountService } = useServices()
  const isSignedIn = accountService.isSignedIn()
  const accountAddress = isSignedIn ? accountService.getAddress() : ''

  const [contract, error] = useAsync(() => api.getContractDetails(address), [address])

  const isAllowedToView = checkIfAllowedToView(isSignedIn, accountAddress, contract)

  const [payouts] = useAsync(
    () => (isSignedIn && isAllowedToView)
      ? payoutQueryService.getPayouts(address)
      : getFakePayouts(),
    [address, isAllowedToView],
  )

  if (contract && contract.type === 'AGREEMENT') {
    return (
      <div className="page">
        <NavBar />
        <AgreementHero
          contract={contract}
          payouts={payouts && payouts.activity}
          isAllowedToView={isAllowedToView}
        />
        <div className="page__content">
          <PaySection contract={contract} />
          <EarningsHistory
            address={address}
            payouts={payouts && payouts.activity}
            isAllowedToView={isAllowedToView}
          />
          <Activity
            contract={contract}
            isAllowedToView={isAllowedToView}
          />
          <RightsHolders contract={contract} />
          <TransferHistory contract={contract} />
        </div>
        <Footer />
        <BottomPanel />
      </div>
    )
  } else if (contract && contract.type === 'ASSET') {
    return <Redirect to={`/asset/${address}`} />
  } else {
    return <LoadingState error={error} />
  }
}
