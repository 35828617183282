import React from 'react'
import { bem } from 'bem-components-react'
import { useHistory } from 'react-router'

export function EmptyAgreements () {
  return (
    <div className="empty-list">
      <div className="empty-list__top-area">
        <p className="empty-list__top-area-text">
          Create, manage, and exchange value with <HighLight>agreements</HighLight>.
        </p>
        <CreateAgreementButton />
      </div>
      <div className="empty-list__bottom-area">
        <p className="empty-list__bottom-area-text">
          An <HighLight>agreement</HighLight> is more than a digital record of your IP.
        </p>
        <CreateAgreementButton />
      </div>
    </div>
  )
}

function CreateAgreementButton () {
  const history = useHistory()

  return (
    <button
      className="button"
      onClick={() => history.push('/create-agreement')}
    >
      Create agreement
    </button>
  )
}

const HighLight = bem.span('hightlight')
