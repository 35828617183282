import React, { ReactNode } from 'react'
import { bem } from 'bem-components-react'

export interface FieldProps {
  label: string,
  children: ReactNode,
}

export function Field ({ label, children }: FieldProps) {
  return (
    <Container>
      <Label>{label}</Label>
      {children}
    </Container>
  )
}

const Container = bem.div('field')
const Label = bem.label('field__label')
