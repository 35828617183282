import React, { useState } from 'react'
import cx from 'classnames'

import { useServices, useSignIn } from 'src/ui/hooks'

export function BottomPanel () {
  const [showBottomBar, setShowBottomBar] = useState(true)
  const { accountService } = useServices()
  const signIn = useSignIn()

  function dismiss () {
    setShowBottomBar(false)
  }

  return (
    <>
      {!accountService.isSignedIn() && (
        <div className={cx('bottom-panel', showBottomBar && 'bottom-panel--visible')}>
          <div>
            <span className="bottom-panel__title">
              Are you a Rights Holder?
            </span>
            <span className="bottom-panel__note">
              Sign in to view payments and earnings history for this agreement.
            </span>
          </div>
          <div>
            <button
              className="bottom-panel__dismiss"
              onClick={() => dismiss()}
            >Dismiss</button>
            <button
              className="button bottom-panel__sign-in"
              onClick={signIn}
            >Sign In</button>
          </div>
        </div>
      )}
    </>
  )
}
