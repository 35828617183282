export const QR_DATA_MOCKS = [
  {
    fs: 'https://original-works-dev-api.herokuapp.com/mock/partner-agreements/0',
    pb: 'https://original-works-dev-api.herokuapp.com/mock/partner-agreements',
    auth: 'Bearer 01234567-abcd-4444-4444-a1b2c3d4e5f6',
  },
  {
    fs: 'https://original-works-dev-api.herokuapp.com/mock/partner-agreements/1',
    pb: 'https://original-works-dev-api.herokuapp.com/mock/partner-agreements',
    auth: 'Bearer 01234567-abcd-4444-4444-a1b2c3d4e5f6',
  },
  {
    fs: 'https://original-works-dev-api.herokuapp.com/mock/partner-agreements/2',
    pb: 'https://original-works-dev-api.herokuapp.com/mock/partner-agreements',
    auth: 'Bearer 01234567-abcd-4444-4444-a1b2c3d4e5f6',
  },
  {
    fs: 'https://original-works-dev-api.herokuapp.com/mock/partner-agreements/5',
    pb: 'https://original-works-dev-api.herokuapp.com/mock/partner-agreements',
    auth: 'Bearer 01234567-abcd-4444-4444-a1b2c3d4e5f6',
  },
  {
    fs: 'https://original-works-dev-api.herokuapp.com/mock/partner-agreements/wrong-data',
    pb: 'https://original-works-dev-api.herokuapp.com/mock/partner-agreements',
    auth: 'Bearer 01234567-abcd-4444-4444-a1b2c3d4e5f6',
  },
  {
    fs: 'https://own-api.revelator.io/fs/release/243982',
    pb: 'https://original-works-dev-api.herokuapp.com/mock/partner-agreements',
    auth: 'Bearer 01234567-abcd-4444-4444-a1b2c3d4e5f6',
  },
  {
    fs: 'https://own-api.revelator.io/fs/release/174602',
    pb: 'https://original-works-dev-api.herokuapp.com/mock/partner-agreements',
    auth: 'Bearer 01234567-abcd-4444-4444-a1b2c3d4e5f6',
  },
  {
    fs: 'https://original-works-dev-api.herokuapp.com/mock/partner-agreements/3',
    pb: 'https://original-works-dev-api.herokuapp.com/mock/partner-agreements',
    auth: 'Bearer 01234567-abcd-4444-4444-a1b2c3d4e5f6',
  },
  {
    fs: 'https://original-works-dev-api.herokuapp.com/mock/partner-agreements/4',
    pb: 'https://original-works-dev-api.herokuapp.com/mock/partner-agreements',
    auth: 'Bearer 01234567-abcd-4444-4444-a1b2c3d4e5f6',
  },
]
