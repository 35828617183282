import React from 'react'

import CheckmarkIconLarge from 'src/assets/icons/checkmark-ico_green.svg'
import { Modal } from 'src/ui/common'

interface SuccessViewProps {
  onClose (): void,
}

export function SuccessView ({ onClose }: SuccessViewProps) {
  return (
    <Modal onClose={onClose}>
      <div className="success-view--wrapper">
        <img className="success-icon" src={CheckmarkIconLarge} alt="success" />
        <p>Follow instructions on your phone to register your agreement</p>
      </div>
    </Modal>
  )
}
