import React from 'react'

import { ApiContract, ApiUserData } from 'original-works-core/models'
import { PayoutActivity } from 'original-works-core/services'

import { AmountField, ProfileField, DetailsField, AssetField, AgreementField } from './fields'
import { ActivityModal } from './ActivityModal'

export interface PaymentsModalProps {
  profile?: ApiUserData,
  contract: ApiContract,
  payout: PayoutActivity,
  isVisible: boolean,
  onClose: () => void,
}

export function PaymentsModal ({ payout, contract, isVisible, onClose, profile }: PaymentsModalProps) {
  return (
    <ActivityModal
      onClose={onClose}
      title={contract.type === 'ASSET' ? 'Royalty received' : 'Payment received'}
      isVisible={isVisible}
    >
      <AmountField value={payout.value}/>
      <ProfileField profile={profile} title="From"/>
      <DetailsField timestamp={payout.timestamp} value={payout.value.formatWithTicker()} />
      {contract.type === 'ASSET'
        ? <AssetField contract={contract} title="For asset" bottom />
        : <AgreementField contract={contract} title="For agreement" bottom />}
    </ActivityModal>
  )
}
