import React from 'react'

export function SignInInstructions () {
  return (
    <ol className="sign-in-instructions">
      <li><span className="sign-in-instructions__text">
        Open Original Works on your phone.
        <a href="https://apps.apple.com/app/original-works/id1484642420">Get it on the App Store</a>
      </span></li>
      <li><span className="sign-in-instructions__text">
        Open your <strong>Profile</strong> and tap <strong>Sign in on web.</strong>
      </span></li>
      <li><span className="sign-in-instructions__text">
        Point your phone to this screen and <strong>scan this code.</strong>
      </span></li>
    </ol>
  )
}
