import React from 'react'
import cx from 'classnames'

import { PayoutActivity } from 'original-works-core/services'
import { getLastPayment, getLifetimeEarnings } from './utils'
import { getHiddenDataString } from '../utils'

export interface HeroFooterProps {
  payouts?: PayoutActivity[],
  isAllowedToView?: boolean,
  isAsset?: boolean,
  isSignedIn?: boolean,
  assetType?: string,
}

export function HeroFooter ({ assetType, isAsset, isSignedIn, isAllowedToView, payouts }: HeroFooterProps) {
  const lastPayment = getLastPayment(payouts)
  const lifetimeEarnings = getLifetimeEarnings(payouts)

  return (
    <div className={cx('hero-footer', !isAllowedToView && 'hero-footer--blurred')}>
      <div className="hero-footer__section">
        <span className="hero-footer__label">Last payment received</span>
        <span className="hero-footer__value">{lastPayment.amount}</span>
      </div>
      <div className="hero-footer__section">
        <span className="hero-footer__label">Last payment date</span>
        <span className="hero-footer__value">{lastPayment.date}</span>
      </div>
      <div className="hero-footer__section">
        <span className="hero-footer__label">Lifetime received</span>
        <span className="hero-footer__value">{lifetimeEarnings}</span>
      </div>
      <div className="hero-footer__sign-in">
        {getHiddenDataString(
          'payments and earnings history for',
          isSignedIn,
          isAsset,
          assetType && assetType.toLowerCase(),
        )}
      </div>
    </div>
  )
}
