import React from 'react'
import { bem } from 'bem-components-react'

import { Values } from 'src/ui/CreateAgreement/values'
import { Modal, ActionQRCode } from 'src/ui/common'

interface AgreementConfirmViewProps {
  agreementId: string,
  agreementData: Values,
  onClose (): void,
}

export function AgreementConfirmView ({ agreementData, agreementId, onClose }: AgreementConfirmViewProps) {
  return (
    <Modal onClose={onClose}>
      <div className="confirm-agreement--wrapper">
        <p className="confirm-agreement__modal-title">Create Agreement</p>
        <Column>
          <ColumnSectionTitle>Title</ColumnSectionTitle>
          <p className="confirm-agreement__title">{agreementData.title}</p>
          {agreementData.description && (
            <>
              <ColumnSectionTitle>Description</ColumnSectionTitle>
              <p className="confirm-agreement__description">{agreementData.description}</p>
            </>
          )}
        </Column>
        <Column>
          <p className="confirm-agreement__message">
            <span>Scan this code </span>
            using Original Works on your phone to authorize and confirm creation of this agreement.
          </p>
          <div className="confirm-agreement__qr-code">
            <ActionQRCode action="agreement" value={agreementId} />
          </div>
        </Column>
        <Column>
          <FooterText>Transaction time: up to 3 minutes</FooterText>
        </Column>
        <Column />
      </div>
    </Modal>
  )
}

const Column = bem.div('confirm-agreement__column')
const ColumnSectionTitle = bem.p('confirm-agreement__column__section-title')
const FooterText = bem.p('confirm-agreement__footer')
