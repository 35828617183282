import dayjs from 'dayjs'
import { CurrencyValue } from 'original-works-core/utils'
import { ApiContract } from 'original-works-core/models'

export async function getFakePayouts () {
  const now = dayjs()
  const payoutValues = [70, 90, 95, 55, 65, 80, 40, 25, 70, 55, 30, 25].reverse()
  return {
    totalPaid: new CurrencyValue(0, 'OWN'),
    activity: payoutValues.map((value, index) => ({
      type: 'PAYOUT' as const,
      transfers: [],
      from: '0x000000000000000000000000000000000000000' + index,
      timestamp: now.subtract(index, 'month').unix(),
      value: new CurrencyValue(value, 'OWN'),
      transactionHash: '0x' + '0'.repeat(64),
    })),
  }
}

export function checkIfAllowedToView (isSignedIn: boolean, account: string, contract?: ApiContract) {
  return isSignedIn &&
    contract &&
    contract.holders
      .map(holder => holder.address)
      .includes(account)
}

export function getHiddenDataString (subject: string, isSignedIn?: boolean, isAsset?: boolean, assetType?: string) {
  return `${
    isSignedIn
      ? 'You must be a holder'
      : 'Sign in'
  } to view ${
    subject
  } this ${
    isAsset
      ? assetType
      : 'agreement'
  }.`
}
