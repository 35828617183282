import { getDefaultProvider } from 'ethers'

import { Config } from './Config'

export function getKovanConfig (): Config {
  return {
    apiUrl: 'https://api.stage.original.works',
    network: 'kovan',
    provider: getDefaultProvider('kovan'),
    nativeCoin: 'ETH',
    OWNTokenAddress: '0x87dB80A2afa0d84C7f6d3AbEfD5E6362dD9b56E5',
    cashoutAddress: '0x0000000000000000000000000000000000000001',
    factoryAddress: '0xD68dD5B86d48f60d695cC4de3B111a737F836349',
    feeManagerAddress: '0xbcd7b771e5ec4F3D3173d7633619e05c183Adb74',
    campaignId: 1,
    // eslint-disable-next-line max-len
    campaignApiKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkucHJvZHVjdGlvbiIsInN1YiI6IjEiLCJpYXQiOjE1Njk4MzI1NzcsImp0aSI6IjE1Njk4MzI1NzcxIiwic2NvcGVzIjpbInJlZGVlbTpjcmVhdGUiXX0.DAg0cJHpJ8w3pTJqL-ve8yM1IgLd3MeMyvfd3LbJSyA',
    stripeKey: 'pk_test_4DmGPmpaGOHr92X4uL2IxoKc',
  }
}
