import React from 'react'
import styled from 'styled-components'

import { ApiAgreement } from 'original-works-core/models'
import { CurrencyValue } from 'original-works-core/utils'

import agreementIcon from 'src/assets/tabBarAgreementsActive.png'
import profileDefaultIcon from 'src/assets/icons/profile.png'
import { H1, H6, H5, H8 } from 'src/ui/common'
import { COLORS } from 'src/ui/colors'
import { useAsync, useServices } from 'src/ui/hooks'
import { formatTimestamp, multilineEllipsis } from 'src/ui/utils'
import { getCover } from '../covers'

export interface RecordingAgreementProps {
  agreement?: ApiAgreement,
  totalPaid: CurrencyValue<'OWN'>,
  lastPayment: CurrencyValue<'OWN'>,
}

export const RecordingAgreement = ({
  agreement = DEFAULT_AGREEMENT,
  totalPaid,
  lastPayment,
}: RecordingAgreementProps) => {
  const { userService, exchangeRateService } = useServices()
  const [user] = useAsync(() => userService.getUser(agreement.ownerAddress), [agreement.ownerAddress])

  const lastPaidUsd = exchangeRateService.toUSD(lastPayment)
  const totalPaidUsd = exchangeRateService.toUSD(totalPaid)

  return (
    <RecordingContainer>
      <Rows>
        <Topic>
          <Icon src={agreementIcon} />
          {agreement.address}
          <TopText>Agreement address</TopText>
        </Topic>
        <Title>
          <H1 blue>{agreement.title}</H1>
        </Title>
        <User>
          <UserIcon src={user?.imageURL || profileDefaultIcon} />
          <UserProperty>
            {user && user.name}
            <H8 gray>Created {formatTimestamp(agreement.timestamp)}</H8>
          </UserProperty>
        </User>
        <div>
          <Content>
            {agreement.description}
          </Content>
          {agreement.description && (agreement.description.length > 300)
            ? <ReadMore />
            : <></>
          }
        </div>
        <Payments>
          <div>
            <Payment>
              <H8 gray>Last royalty payment</H8>
              <H5 block>${lastPaidUsd.formatWithTicker()}</H5>
            </Payment>
          </div>
          <div>
            <Payment>
              <H8 gray>Total paid</H8>
              <H5 block>${totalPaidUsd.formatWithTicker()}</H5>
            </Payment>
          </div>
        </Payments>
      </Rows>
      <BigImage src={getCover(agreement.address)} />
    </RecordingContainer>
  )
}

const DEFAULT_AGREEMENT: ApiAgreement = {
  address: '',
  ownerAddress: '...',
  title: '...',
  description: '...',
  balance: '0',
  totalSupply: '0',
  timestamp: 0,
  icon: undefined,
}

const RecordingContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 12px;
  padding: 12px;
  width: 100vw;
  height: 50vw;
`

const BigImage = styled.div<{ src: string }>`
  border-radius: 8px;
  background-image: url(${({ src }) => src});
  background-size: cover;
`

const Rows = styled.div`
  display: grid;
  border-radius: 8px;
  background-color: #ffffff;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
`

const Topic = styled.div`
  display:flex;
  align-items: center;
  margin-left: 68px;
`

const TopText = styled(H8).attrs({ gray: true })`
  padding-left: 5px;
`

const Icon = styled.img`
  height: 25px;
  width: 25px;
  margin-right: 10px;
`

const Title = styled.div`
  align-self: flex-end;
  margin-left: 68px;
`

const User = styled.div`
  display: grid;
  grid-template-columns : 50px 1fr;
  align-items: Center;
  margin-left: 68px;
`

const UserProperty = styled.div`
  display:grid;
  grid-template-rows: 20px 16px;
`

const UserIcon = styled.img`
  width:40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 20px;
`

const Payments = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;
  align-content: center;
  margin-left: 68px;
  margin-right: 67px;
`

const ReadMore = styled(H6).attrs({ blue: true })`
  margin-left: 68px;
  &:after{
    content: "Read more";
  }
`

const Payment = styled.div`
  padding-top: 10px;
  text-align: center;
  border-top: solid 1px ${COLORS.lineGray};
`

const Content = styled(H6).attrs({ block: true })`
  width: 615px;
  margin-bottom: 10px;
  margin-left: 68px;
  ${multilineEllipsis('1.5em', 3)}
`
