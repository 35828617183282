import { ApiActivityContract, Factsheet } from 'original-works-core/models'

import { recordingIcon, releaseIcon, songIcon } from 'src/assets/icons/assetContractIcons'
import agreementIcon from 'src/assets/icons/agreement.svg'

export function getDefaultAssetIcon (type: string) {
  switch (type.toUpperCase()) {
    case 'RECORDING': return recordingIcon
    case 'SONG': return songIcon
    case 'RELEASE': return releaseIcon
    default: return agreementIcon
  }
}

export function getAssetIcon (factsheet: Factsheet) {
  return factsheet.artwork || getDefaultAssetIcon(factsheet.type)
}

export function getContractIcon (contract: ApiActivityContract) {
  switch (contract.type) {
    case 'ASSET':
      return getAssetIcon(contract.factsheet)
    case 'AGREEMENT':
    default:
      return agreementIcon
  }
}
