import { Api } from './Api'
import { AccountService } from './AccountService'

export class RedeemService {
  constructor (
    private api: Api,
    private accountService: AccountService,
    private campaignId: number,
    private campaignApiKey: string,
  ) {}

  async getRedeemCode (ETH: string, OWN: string): Promise<string> {
    const isAdmin = await this.api.isAdmin(this.accountService.getAddress())
    if (!isAdmin) {
      throw new Error('Forbidden')
    }
    const { redeemCode } = await this.api.getRedeemCode(
      this.campaignId, this.campaignApiKey, ETH, OWN,
    )
    return redeemCode
  }
}
