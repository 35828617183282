import React, { useState } from 'react'
import { useHistory } from 'react-router'

import { useServices, useObservable } from 'src/ui/hooks'
import AgreementWizard from './AgreementWizard'
import { AgreementModal } from './AgreementModal'
import { Values } from './values'

function CreateAgreement () {
  const { accountService } = useServices()
  const history = useHistory()

  const [address] = useObservable(() => accountService.getAddressObservable(), [])
  const [values, setValues] = useState<Values | undefined>()
  const [modalVisible, setModalVisible] = useState(false)

  function onSubmit (values: Values) {
    setValues(values)
    setModalVisible(true)
  }

  return (
    <>
      {values && modalVisible && (
        <AgreementModal
          agreementData={values}
          onClose={() => setModalVisible(false)}
        />
      )}
      {address && (
        <AgreementWizard
          userAddress={address}
          onClose={history.goBack}
          onSubmit={onSubmit}
        />
      )}
    </>
  )
}

export default CreateAgreement
