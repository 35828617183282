import React, { ReactNode } from 'react'
import cx from 'classnames'
import arrowIcon from 'src/assets/icons/arrow_light-blue.svg'

export interface ListNavigationItemProps {
  active: boolean,
  onClick: () => void,
  children?: ReactNode,
}

export function ListNavigationItem ({ active, onClick, children }: ListNavigationItemProps) {
  return (
    <button
      className={cx('list-navigation__item', active && 'list-navigation__item--active')}
      onClick={onClick}
    >
      {children}
      {active && <img src={arrowIcon} alt="arrow" />}
    </button>
  )
}
