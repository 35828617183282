import { useState } from 'react'
import { ApiContract, ApiFullUserData } from 'original-works-core/models'

import { useAsync } from './useAsync'
import { useServices } from './useServices'

export function useUserOrContract (address: string, deps: readonly any[]) {
  const { userService, api, addressUtilsService } = useServices()
  const [user, setUser] = useState<Omit<ApiFullUserData, 'address'>>()
  const [contract, setContract] = useState<ApiContract>()

  useAsync(async () => {
    if (await addressUtilsService.isContract(address)) {
      setContract(await api.getContractDetails(address))
    } else {
      setUser(await userService.getUser(address))
    }
  }, deps)

  return { user, contract }
}
