import React from 'react'
import { Field } from 'src/ui/common'

function TitlePreview (props: { title: string }) {
  return (
    <>
      <p className="create-agreement-preview__box-label">Title</p>
      <p className="create-agreement-preview__title">{props.title}</p>
    </>
  )
}

function DescriptionPreview (props: { description: string }) {
  return (
    <>
      <p className="create-agreement-preview__box-label">Description</p>
      <p className="create-agreement-preview__description">{props.description}</p>
      <p className="create-agreement-preview__description-link">Edit description</p>
    </>
  )
}

interface PreviewProps {
  title?: string,
  description?: string,
  tokens?: string,
  onClick (): void,
}

export function Preview ({ title, description, tokens, onClick }: PreviewProps) {
  return (
    <Field label="Preview">
      <div className="create-agreement-preview__box" onClick={onClick}>
        {title && <TitlePreview title={title} />}
        {description && <DescriptionPreview description={description} />}
      </div>
    </Field>
  )
}
