import { useState, useEffect } from 'react'
import { providers } from 'ethers'

type State =
  | { type: 'NO_ETHEREUM' }
  | { type: 'NOT_ENABLED' }
  | { type: 'ENABLING' }
  | { type: 'ERROR', message: string }
  | EnabledState

interface EnabledState {
  type: 'ENABLED',
  account: string,
  network: string,
  provider: providers.JsonRpcProvider,
}

const ethereum = (window as any).ethereum
function getInitialState (): State {
  if (!ethereum) {
    return { type: 'NO_ETHEREUM' }
  } else if (!ethereum.selectedAddress) {
    return { type: 'NOT_ENABLED' }
  } else {
    return {
      type: 'ENABLED',
      account: ethereum.selectedAddress,
      network: getNetworkName(ethereum.networkVersion),
      provider: new providers.Web3Provider(ethereum),
    }
  }
}

export function useMetamask () {
  const [state, setState] = useState<State>(getInitialState)

  function enable () {
    if (state.type === 'NOT_ENABLED' || state.type === 'ERROR') {
      setState({ type: 'ENABLING' })
    }
  }

  useEffect(() => {
    if (state.type === 'ENABLING') {
      let cancelled = false
      ethereum.enable().then(
        (accounts: string[]) => !cancelled && setState({
          type: 'ENABLED',
          account: accounts[0],
          network: getNetworkName(ethereum.networkVersion),
          provider: new providers.Web3Provider(ethereum),
        }),
        (error: any) => !cancelled && setState({ type: 'ERROR', message: (error && error.message) || 'Unknown error' }),
      )
      return () => { cancelled = true }
    }
  }, [state])

  useEffect(() => {
    if (ethereum !== undefined) {
      ethereum.autoRefreshOnNetworkChange = false
    }
  }, [])

  useEffect(() => {
    if (state.type === 'ENABLED') {
      const callback = (accounts: string[]) => setState({
        type: 'ENABLED',
        account: accounts[0],
        network: state.network,
        provider: state.provider,
      })
      ethereum.on('accountsChanged', callback)
      return () => ethereum.off('accountsChanged', callback)
    }
  }, [state])

  useEffect(() => {
    if (state.type === 'ENABLED') {
      const callback = (networkId: string) => setState({
        type: 'ENABLED',
        account: state.account,
        network: getNetworkName(networkId),
        provider: new providers.Web3Provider(ethereum),
      })
      ethereum.on('networkChanged', callback)
      return () => ethereum.off('networkChanged', callback)
    }
  }, [state])

  return { state, enable }
}

function getNetworkName (networkId: string) {
  switch (networkId) {
    case '1': return 'mainnet'
    case '2': return 'morden'
    case '3': return 'ropsten'
    case '4': return 'rinkeby'
    case '5': return 'goerli'
    case '42': return 'kovan'
    default: return 'private'
  }
}
