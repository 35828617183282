import React, { FormEvent, useEffect, useState } from 'react'
import cx from 'classnames'

import { ApiUserData, Holder } from 'original-works-core/models'

import { ProfileImage, Spinner } from 'src/ui/common'
import { useAsync, useServices } from 'src/ui/hooks'
import { shorten } from 'src/ui/utils'

interface UserData extends ApiUserData {
  address: string,
}

interface WalletAddressInputProps {
  holders: Holder[],
  onSubmit (address: string): void,
}

export function WalletAddressInput (props: WalletAddressInputProps) {
  const [value, setValue] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  const { userService } = useServices()

  const [user] = useAsync((): Promise<ApiUserData> => {
    if (isValidAddress(value)) {
      setLoading(true)
      return userService.getUser(value)
    } else {
      return new Promise(() => [undefined, undefined])
    }
  }, [value])

  useEffect(() => {
    if (user) setLoading(false)
  }, [user])

  function onAddressChange (newValue: string) {
    setValue(newValue)
    setError(newValue !== '' && !isValidAddress(newValue))
  }

  function onSelect (e: FormEvent<HTMLButtonElement>) {
    e.preventDefault()
    setValue('')
    setError(false)
    props.onSubmit(value)
  }

  function isValidAddress (value: string) {
    const alreadyExists = props.holders.some(
      holder => holder.address.toLowerCase() === value.toLowerCase(),
    )
    return value.match(/^0x[\da-fA-F]{40}$/) && !alreadyExists
  }

  function onBlur () {
    setIsFocused(false)
    setError(false)
  }

  return (
    <div className="holder-search__wrapper">
      <div className={cx(
        'holder-search',
        isFocused && 'holder-search--active',
      )}>
        <div className="holder-search__input-wrapper">
          <input
            className="holder-search__input"
            placeholder="Add rights holders by 0x Address"
            value={value}
            onChange={e => onAddressChange(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={onBlur}
            autoComplete="off"
          />
          <div className="holder-search__input-right">
            {loading && <Spinner className="holder-search__spinner" />}
          </div>
        </div>
        <DropdownMenu
          user={user && { ...user, address: value }}
          onSelect={onSelect}
          error={error}
        />
      </div>
    </div>
  )
}

export interface DropdownMenuProps {
  user?: UserData,
  onSelect (e: FormEvent<HTMLButtonElement>): void,
  error: boolean,
}

export interface DropdownMenuItemProps {
  user: UserData,
  onButtonClick (e: FormEvent<HTMLButtonElement>): void,
}

function DropdownMenu ({ user, onSelect, error }: DropdownMenuProps) {
  const footerTextHightlight = error ? 'Invalid address or holder already added. ' : 'If they don’t have a wallet? '
  const footerText = error ? 'Please provide valid 0x address.' : 'Invite them to get the app and create one now.'

  return (
    <div className="holders-dropdown">
      {user && <DropdownMenuItem user={user} onButtonClick={onSelect} />}
      <div className="holders-dropdown-footer">
        <p className="holders-dropdown-footer-message">
          <span className="holders-dropdown-footer-message-highlight">{footerTextHightlight}</span>
          {footerText}
        </p>
      </div>
    </div>
  )
}

function DropdownMenuItem ({ user, onButtonClick }: DropdownMenuItemProps) {
  return (
    <div className="holders-dropdown-item">
      <ProfileImage src={user.imageURL} className="holders-dropdown-item-image" />
      <p className="holders-dropdown-item-name">{user.name}</p>
      <p className="holders-dropdown-item-email">
        {user.isPublic ? user.email : 'Email is private'}
      </p>
      <p className="holders-dropdown-item-address">{shorten(user.address)}</p>
      <button type="button" className="button add-holder-button" onClick={onButtonClick}>Add</button>
    </div>
  )
}
