import React, { ChangeEvent, useState } from 'react'
import styled from 'styled-components'

import { Loan } from 'original-works-core/models'
import { CurrencyValue } from 'original-works-core/utils'

import { ContractIcon, ProfileImage } from 'src/ui/common'
import { capitalize } from 'src/ui/Contracts/ContractList/utils'
import { useAsync, useServices } from 'src/ui/hooks'
import { getContractIcon } from 'src/ui/utils'

export interface MicroLendingTableRowProps {
  loan: Loan,
  onApprove(id: number, interestRate: number): void,
}

const MIDDOT_WITH_SPACES = ' \u00B7 '

export function MicroLendingTableRow ({ loan, onApprove }: MicroLendingTableRowProps) {
  const { api } = useServices()
  const [contract] = useAsync(() => api.getContractDetails(loan.asset), [loan.asset])
  const [borrower] = useAsync(() => api.getUserDetails(loan.borrower), [loan.borrower])
  const [interestRate, setInterestRate] = useState('2')

  const amount = new CurrencyValue(loan.amount.value, 'OWN')

  const onInterestRateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInterestRate(e.target.value)
  }

  const onApproveClick = () => {
    onApprove(loan.id, Number(interestRate))
  }

  return (
    <tr>
      <td>
        <AssetContainer>
          <ProfileImage src={borrower?.imageURL} />
          <div>
            <AssetTitle>{borrower ? borrower.name : '...'}</AssetTitle>
            <p>{borrower?.email}</p>
          </div>
        </AssetContainer>
      </td>
      <td>{amount.format()}</td>
      <td>{loan.status === 'REQUESTED'
        ? (<InputWrapper>
          <input
            type="number"
            name="interestRate"
            placeholder='2'
            min={0}
            max={100}
            value={interestRate}
            onChange={onInterestRateChange}
          />%
        </InputWrapper>)
        : `${loan.interestRate}%`}
      </td>
      <td>{
        contract && contract.type === 'ASSET' && (
          <AssetContainer>
            <ContractIcon src={getContractIcon(contract)}/>
            <div>
              <AssetTitle>{contract.factsheet.title}</AssetTitle>
              <p>{[capitalize(contract.factsheet.type), contract.factsheet.subtitle].join(MIDDOT_WITH_SPACES)}</p>
            </div>
          </AssetContainer>
        )
      }</td>
      <td>
        { loan.status === 'REQUESTED'
          ? <button type="button" onClick={onApproveClick}>
            Approve
          </button>
          : loan.status === 'APPROVED'
            ? 'Awaiting acceptance'
            : loan.status
        }
      </td>
    </tr>
  )
}

const AssetContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  & > img {
    min-width: 40px;
    margin-right: 10px;
  }
  & > div {
      display: flex;
      flex-direction: column;
  }
  & > p {
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
  }
`

const AssetTitle = styled.p`
  margin-bottom: 5px;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 5px;
`
