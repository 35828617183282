import React from 'react'

import { OrderedList } from 'original-works-core/models'
import { toFormattedDuration } from 'original-works-core/utils'

export interface OrderedListProps {
  detailsSet: OrderedList,
}

export function OrderedListDisplay ({ detailsSet }: OrderedListProps) {
  return (
    <ol className="asset-info-details asset-info-details--ordered-list">
      {detailsSet.items.map((item, index) => (
        <li className="asset-info-details__item" key={item.title + index}>
          <p className="asset-info-details__number">{index + 1}.</p>
          <p className="asset-info-details__title">{item.title}</p>
          <p className="asset-info-details__artist">{item.artist}</p>
          <p className="asset-info-details__isrc">{item.isrc}</p>
          <p className="asset-info-details__info">
            {(item.duration && toFormattedDuration(item.duration)) || item.info}</p>
        </li>
      ))}
    </ol>
  )
}
