import { GroupedList, GroupedListItem } from 'original-works-core/models'

export function groupDetailSetItems (detailsSet: GroupedList) {
  const groupedItems: {[key: string]: GroupedListItem[]} = {}

  detailsSet.items
    .map(item => item.group)
    .reduce((acc, curr) => {
      acc[curr] = detailsSet.items.filter(item => item.group === curr)
      return acc
    }, groupedItems)

  return groupedItems
}
