import React, { useState } from 'react'
import styled from 'styled-components'

import { CurrencyValue } from 'original-works-core/utils'
import { ApiAgreement } from 'original-works-core/models'

import { H6, H8, H5 } from 'src/ui/common/'
import { COLORS } from 'src/ui/colors'
import { useServices } from 'src/ui/hooks'
import { PayModal } from '../PayModal/PayModal'

export interface AgreementPayProps {
  requiredPayment: CurrencyValue<'EUR'>,
  agreement: ApiAgreement | undefined,
}

export function AgreementPay ({ requiredPayment, agreement }: AgreementPayProps) {
  const { exchangeRateService } = useServices()
  const [modalVisible, setModalVisible] = useState(false)

  const requiredPaymentUsd = exchangeRateService.toUSD(requiredPayment)
  const requiredPaymentOwn = exchangeRateService.fromUSD(requiredPaymentUsd, 'OWN')

  return (
    <Container>
      <div>
        <Title>
          You owe
          <Value> ${requiredPaymentUsd.formatWithTicker()} </Value>
          <H5 gray> ({requiredPayment.formatWithTicker()}) </H5>
          in royalties to this agreement.
        </Title>
        <H8 gray>Approximately {requiredPaymentOwn.format()}</H8>
      </div>
      <PaymentButton onClick={() => setModalVisible(true)}>
        Pay royalty
      </PaymentButton>
      {modalVisible && agreement && (
        <PayModal
          agreement={agreement}
          onClose={() => setModalVisible(false)}
          requiredPayment={requiredPayment}
          displayEUR
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  background-color: white;
  border-radius: 8px;
  max-width:836px;
  display: grid;
  align-items: center;
  grid-template-columns: 5fr 1fr ;
  height: 100px;
  margin: 60px auto 0 auto;
  padding: 0 30px 0 30px;
`
const Title = styled(H6).attrs({ block: true })`
  padding-bottom: 10px;
`

const PaymentButton = styled.button`
  background-color: ${COLORS.lightBlue};
  color: ${COLORS.white};
  padding: 10px 27px;
  border-radius: 8px;
  font-size: 15px;
  width: 140px;
  height: 40px;
`
const Value = styled.span`
  color: ${COLORS.lightBlue};
`
