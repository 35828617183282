import React from 'react'
import copy from 'copy-to-clipboard'
import { toDataURL } from 'qrcode'
import { useAsync } from '../hooks'

export interface ActionQRCodeProps {
  action: string,
  value: string,
}

export function ActionQRCode ({ action, value }: ActionQRCodeProps) {
  return <QRCode data={`${action}:${value}`} />
}

export interface QRCodeProps {
  data: string,
}

export function QRCode ({ data }: QRCodeProps) {
  const [dataUrl] = useAsync(() => toDataURL(data), [data])

  function onClick () {
    copy(data)
    console.log(`%c${data}`, 'color: blue')
  }

  return (
    <img className="qr-code" src={dataUrl || ''} alt="QRCode" onClick={onClick} />
  )
}
