import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

import { useAsync, useObservable, useServices, useSignOut, useSignIn } from './hooks'
import { LoginRedirect } from './LoginRedirect'

export function AdminRoute (props: RouteProps) {
  const { accountService, api } = useServices()
  const [address] = useObservable(() => accountService.getAddressObservable(), [])
  const [isAdmin] = useAsync(async () => {
    if (address === undefined) {
      return undefined
    }
    if (address === null) {
      return false
    }
    try {
      return await api.isAdmin(address)
    } catch {
      return false
    }
  }, [address])

  if (address === undefined || isAdmin === undefined) {
    return null
  }
  if (address === null) {
    return <LoginRedirect />
  }
  if (address && isAdmin) {
    return <Route {...props} />
  }
  return <NotAdmin />
}

function NotAdmin () {
  const signOut = useSignOut()
  const signIn = useSignIn()

  function onClick () {
    signOut()
    signIn()
  }

  return (
    <>
      <h1>You&apos;re not authorized to access this page.</h1>
      <button onClick={onClick}>Sign in as an admin</button>
    </>
  )
}
