import React, { useState } from 'react'

import { useAsync, useServices } from 'src/ui/hooks'
import CashoutsTableHead from './CashoutsTableHead'
import CashoutsTableRow from './CashoutsTableRow'
import { AdminPage } from '../AdminPage'

function Cashouts () {
  const { cashoutService } = useServices()
  const [lastHash, setLastHash] = useState('')
  const [cashouts] = useAsync(() => cashoutService.getCashouts(), [lastHash])

  async function markResolved (txHash: string) {
    await cashoutService.resolveCashout(txHash)
    setLastHash(txHash)
  }

  return (
    <AdminPage>
      <div className="cashouts-screen">
        <h1 className="cashouts-screen__title">Cashouts</h1>
        <table className="cashouts-table">
          <CashoutsTableHead />
          <tbody>
            {cashouts && cashouts.map(cashout => (
              <CashoutsTableRow
                key={cashout.transactionHash}
                cashout={cashout}
                cashoutService={cashoutService}
                onMarkResolved={() => markResolved(cashout.transactionHash)}
              />
            ))}
          </tbody>
        </table>
      </div>
    </AdminPage>
  )
}

export default Cashouts
