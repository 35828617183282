import { utils, providers } from 'ethers'

import { Api } from './Api'

const tokenInterface = new utils.Interface([
  'event Transfer(address indexed from, address indexed to, uint256 value)',
])

export interface Cashout {
  transactionHash: string,
  resolved: boolean,
  paypalAddress: string,
  type: 'paypal',
  createdAt: string,
}

export class CashoutService {
  constructor (
    private api: Api,
    private provider: providers.Provider,
    private OWNTokenAddress: string,
    private cashoutAddress: string,
  ) {
  }

  async getCashouts (): Promise<Cashout[]> {
    return this.api.getCashouts()
  }

  async resolveCashout (txHash: string) {
    return this.api.resolveCashout(txHash)
  }

  async getTransactionDetails (txHash: string) {
    const receipt = await this.provider.waitForTransaction(txHash)
    const logs = receipt.logs!
      .filter(log => log.address === this.OWNTokenAddress)
      .map((log) => {
        try {
          return tokenInterface.parseLog(log)
        } catch (e) {
          return null
        }
      })
      .filter(event => event && event.args.to === this.cashoutAddress)

    if (logs[0]) {
      return {
        value: utils.formatEther(logs[0]!.args.value),
      }
    }
    return {
      error: true,
    }
  }
}
