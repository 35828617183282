import React, { useState } from 'react'

import { ApiAgreementContract } from 'original-works-core/models'
import { PayModal } from 'src/ui/Teosto/PayModal/PayModal'
import { CurrencyValue } from 'original-works-core/utils'

export interface PaySectionProps {
  contract: ApiAgreementContract,
}

export function PaySection ({ contract }: PaySectionProps) {
  const [showModal, setShowModal] = useState(false)

  return (
    <section className="pay-section">
      <h2 className="pay-section__title">Send a payment to this agreement</h2>
      <p className="pay-section__text">
        Use the agreements address or scan it’s QR code with your wallet to send
        a payment that will be split automatically between all current rights holders.
      </p>
      <button onClick={() => setShowModal(true)} className="button pay-section__button">Pay now</button>
      <a href="https://original.works/en/contact" className="pay-section__link">
        Learn more
      </a>
      {showModal && (
        // TODO: Fix ugly contract cast
        <PayModal
          agreement={contract as any}
          onClose={() => setShowModal(false)}
          requiredPayment={new CurrencyValue(0, 'EUR')}
        />
      )}
    </section>
  )
}
