export const COLORS = {
  white: 'white',
  darkGray: '#888888',
  dividerGray: '#e6e6e6',
  lightGray: '#cacaca',
  lightBlue: '#7341f3',
  darkBlue: '#3b10a7',
  black: '#2b2b2b',
  green: '#00b887',
  lineGray: '#ebeae9',
}
