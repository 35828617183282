import React from 'react'
import { Holder } from 'original-works-core/models'
import { formatNumber, toFormattedFixedPercentage } from 'original-works-core/utils'
import { HolderProfile } from './HolderProfile'
import { shorten } from 'src/ui/utils'

export interface RightHoldersListItemProps {
  holder: Holder,
  totalSupply: string,
}

export function RightHoldersListItem ({ holder, totalSupply }: RightHoldersListItemProps) {
  return (
    <li className="rights-holders-list-item">
      <HolderProfile holderAddress={holder.address}/>
      <span className="rights-holders-list-item__admin">{holder.isAdmin ? 'Admin' : ''}</span>
      <span className="rights-holders-list-item__address">{shorten(holder.address)}</span>
      <div className="rights-holders-list-item__balance">
        <span className="rights-holders-list-item__shares">{formatNumber(holder.balance)} Shares</span>
        <span className="rights-holders-list-item__percentage">
          {toFormattedFixedPercentage(holder.balance, totalSupply)}
        </span>
      </div>
    </li>
  )
}
