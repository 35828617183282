import React from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router'

import { CurrencyValue } from 'original-works-core/utils'

import { NavBar, Footer } from 'src/ui/common'
import { useServices, useAsync } from 'src/ui/hooks'
import { RecordingAgreement } from './RecordingAgreement'
import { AgreementPay } from './AgreementPayRoyalty'
import { AgreementListOfActivity } from './AgreementListOfActivity'

export const AgreementInfoPage = (props: RouteComponentProps<{ address: string }>) => {
  const { address } = props.match.params

  const { agreementDetailsService, teostoPayoutService, payoutQueryService } = useServices()
  const [agreement] = useAsync(async () => {
    const agreements = await agreementDetailsService.getAgreementDetails(address)
    return agreements || null
  }, [])

  const [result] = useAsync(() => payoutQueryService.getPayouts(address), [address])
  const { activity, totalPaid } = result || { activity: [], totalPaid: new CurrencyValue(0, 'OWN') }
  const lastPayment = (activity[0] && activity[0].value) || new CurrencyValue(0, 'OWN')

  const [nextPayoutValue, error] = useAsync(
    () => teostoPayoutService.getPayoutAmount(address),
    [address],
  )

  return (
    <div className="page">
      <NavBar />
      <Main>
        <RecordingAgreement
          agreement={agreement}
          totalPaid={totalPaid}
          lastPayment={lastPayment}
        />
        {!error && (
          <AgreementPay
            requiredPayment={nextPayoutValue || new CurrencyValue(0, 'EUR')}
            agreement={agreement}
          />
        )}
        <AgreementListOfActivity
          activities={activity || []}
          agreementName={(agreement && agreement.title) || '...'}
          agreementOwner={(agreement && agreement.ownerAddress) || ''}
        />
      </Main>
      <Footer />
    </div>
  )
}

const Main = styled.div`
  align-content : center;
`
