import React from 'react'

import { Details } from 'original-works-core/models'
import { FieldListDisplay } from './FieldListDisplay'
import { GroupedListDisplay } from './GroupedListDisplay'
import { OrderedListDisplay } from './OrderedListDisplay'
import { UnorderedListDisplay } from './UnorderedListDisplay'

export interface DetailsDisplayProps {
  detailsSet?: Details,
}

export function DetailsDisplay ({ detailsSet }: DetailsDisplayProps) {
  if (!detailsSet) {
    return null
  }

  switch (detailsSet.type) {
    case 'GROUPED_LIST':
      return (
        <GroupedListDisplay detailsSet={detailsSet} />
      )
    case 'ORDERED_LIST':
      return (
        <OrderedListDisplay detailsSet={detailsSet} />
      )
    case 'FIELD_LIST':
      return (
        <FieldListDisplay detailsSet={detailsSet} />
      )
    case 'UNORDERED_LIST':
      return <UnorderedListDisplay detailsSet={detailsSet} />
  }
}
