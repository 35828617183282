import { useState } from 'react'

export function useModal () {
  const [isVisible, setIsVisible] = useState(false)

  return {
    isVisible,
    open: () => setIsVisible(true),
    close: () => setIsVisible(false),
  }
}
