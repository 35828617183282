import React from 'react'

export function MicroLendingTableHead () {
  return (
    <thead className="cashouts-table__head">
      <tr>
        <th>Borrower</th>
        <th>Amount</th>
        <th>Interest Rate</th>
        <th>Asset</th>
        <th>Status</th>
      </tr>
    </thead>
  )
}
