import { Stripe, loadStripe } from '@stripe/stripe-js'

import { Api } from './Api'

export class StripeService {
  private stripe: Promise<Stripe | null> | undefined = undefined
  constructor (
    private api: Api,
    private stripeKey: string,
  ) {
  }

  async redirectToCheckout (id: string, amount: string) {
    const stripe = await this.getStripe()

    const { sessionId } = await this.api.connectStripeSession(id, amount)
    await stripe.redirectToCheckout({ sessionId })
  }

  private async getStripe () {
    if (!this.stripe) {
      this.stripe = loadStripe(this.stripeKey)
    }

    const stripe = await this.stripe

    if (!stripe) throw new Error('Stripe initializing error')

    return stripe
  }
}
