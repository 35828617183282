import React, { useState } from 'react'

import { Details } from 'original-works-core/models'
import { Modal } from 'src/ui/common'
import { DetailsDisplay } from './DetailsDisplay'

export interface DetailsDisplayLinkProps {
  title: string,
  detailsSet: Details,
}

export function DetailsDisplayLink ({ title, detailsSet }: DetailsDisplayLinkProps) {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <div onClick={() => setModalVisible(true)}>
      <span className="asset-info-details-list__link">{detailsSet.name}</span>
      {modalVisible && (
        <Modal
          onClose={() => setModalVisible(false)}
          small={detailsSet.type === 'GROUPED_LIST'}
          className="asset-info-details-list-modal"
        >
          <div className="asset-info-details-list__header">
            <span className="asset-info-details-list__title">{title}</span>
            <span className="asset-info-details-list__name">{detailsSet.name}</span>
          </div>
          <DetailsDisplay detailsSet={detailsSet}/>
        </Modal>
      )}
    </div>
  )
}
