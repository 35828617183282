import React, { useState } from 'react'
import cx from 'classnames'

import { ApiContract } from 'original-works-core/models'
import { Activity } from 'original-works-core/services'

import { useServices } from 'src/ui/hooks'
import { ActivityListItem } from './ActivityListItem'
import { NoPaymentsBar } from './NoPaymentsBar'
import { LoadMoreButton } from '../../Components'
import { getHiddenDataString } from '../../utils'

export interface PaymentsListProps {
  contract: ApiContract,
  isAsset?: boolean,
  activity?: Activity[],
  isAllowedToView?: boolean,
  assetType?: string,
}

export function ActivityList ({ assetType, isAllowedToView, contract, isAsset, activity }: PaymentsListProps) {
  const INITIAL_LIST_LENGTH = 5

  const [expanded, setExpanded] = useState(false)
  const { accountService } = useServices()
  const isSignedIn = accountService.isSignedIn()

  return (
    <div className={cx(
      'contract-activity-list__wrapper',
      !isAllowedToView && 'contract-activity-list__wrapper--blurred',
    )}>
      {(activity && activity.length)
        ? <>
          <div className="contract-activity-list__header">
            <span className="contract-activity-list__label">Transaction</span>
            <span className="contract-activity-list__label">Date</span>
          </div>
          <ol className="contract-activity-list">
            {activity
              .slice(0, expanded ? activity.length : INITIAL_LIST_LENGTH)
              .map((action, index) => (
                <ActivityListItem activity={action} contract={contract} key={index} />
              ))
            }
          </ol>
          <LoadMoreButton
            hidden={!isAllowedToView || expanded || activity.length <= INITIAL_LIST_LENGTH}
            onClick={() => setExpanded(true)}
          />
          <div className="contract-activity-list__signed-out">
            <span className="contract-activity-list__signed-out-text">
              {getHiddenDataString(
                'the payments received by',
                isSignedIn,
                isAsset,
                assetType && assetType.toLowerCase(),
              )}
            </span>
          </div>
        </>
        : <NoPaymentsBar />
      }
    </div>
  )
}
