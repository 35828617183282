import React from 'react'
import cx from 'classnames'

export interface OwnershipAndCollectionProps {
  enableRevenueCollection: boolean,
}

export function OwnershipAndCollection ({ enableRevenueCollection }: OwnershipAndCollectionProps) {
  return (
    <>
      <h2 className="ownership-and-collection__title">Registered with</h2>
      <div className="ownership-and-collection">
        <div className="ownership-and-collection__field">
          <h2 className="ownership-and-collection__field-title">Ownership</h2>
          <span className="ownership-and-collection__text">
            Ownership of this release is shared between rights holders on Original Works.
          </span>
        </div>
        <div className={
          cx(
            'ownership-and-collection__field',
            !enableRevenueCollection && 'ownership-and-collection__field--fade-out',
          )}
        >
          <h2 className="ownership-and-collection__field-title">Collection</h2>
          <span className="ownership-and-collection__text">
            <strong>Royalties for this release</strong>
            {enableRevenueCollection
              ? ' are being paid via Original Works.'
              : ' are not being paid via Original Works.'
            }
          </span>
        </div>
      </div>
    </>
  )
}
