import React from 'react'
import { useAsync, useServices } from 'src/ui/hooks'

interface OwnerInfoProps {
  address: string,
}

export function OwnerInfo ({ address }: OwnerInfoProps) {
  const { userService } = useServices()
  const [user] = useAsync(() => userService.getUser(address), [address])

  return (
    <div className="contracts-list__item-owner">
      {user && <img src={user.imageURL || undefined} />}
      {user && <p>{user.name}</p>}
      {!user && <p>...</p>}
    </div>
  )
}
