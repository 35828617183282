import React from 'react'
import cx from 'classnames'

import { EarningsSummaryData, CurrencyValue, Period } from 'original-works-core/utils'

export interface SummaryStatsProps {
  period: Period,
  isAsset: boolean,
  summaryData: EarningsSummaryData,
}

export function SummaryStats ({ period, isAsset, summaryData }: SummaryStatsProps) {
  const fields = getFields(summaryData, period, isAsset)

  return (
    <div className="eh-summary">
      {fields.map((field, index) => (
        <div key={index} className={cx('eh-summary__field', field.faded && 'eh-summary__field--faded')}>
          <span className="eh-summary__value">{field.value}</span>
          <span className="eh-summary__label">{field.title}</span>
        </div>
      ))}
    </div>
  )
}

function getFields (summaryData: EarningsSummaryData, period: Period, isAsset: boolean) {
  if (isAsset && period === 'daily') {
    return [
      field('Last 24 hours', summaryData.earned.last24Hours),
      field('Last 30 days', summaryData.earned.last30Days),
      field('Last 24 hours', summaryData.received.last30Days),
      field('Last 30 days', summaryData.received.last30Days),
    ]
  } else if (isAsset && period === 'monthly') {
    return [
      field('Last 3 months', summaryData.earned.last3Months),
      field('Lifetime earnings', summaryData.earned.lifetime),
      field('Last 3 months', summaryData.received.last3Months),
      field('Lifetime received', summaryData.received.lifetime),
    ]
  } else if (!isAsset && period === 'daily') {
    return [
      field('Last 24 hours', summaryData.received.last24Hours),
      field('Last 7 days', summaryData.received.last7Days),
      field('Last 30 days', summaryData.received.last30Days),
      field('Lifetime received', summaryData.received.lifetime),
    ]
  } else { // !isAsset && period === 'monthly'
    return [
      field('Last 3 months', summaryData.received.last3Months),
      field('Last 6 months', summaryData.received.last6Months),
      field('Last 12 months', summaryData.received.last12Months),
      field('Lifetime received', summaryData.received.lifetime),
    ]
  }
}

function field (title: string, value?: CurrencyValue<'USD'>) {
  return {
    title,
    value: value ? value.format() : '$0.00',
    faded: !value,
  }
}
