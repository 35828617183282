import React from 'react'

import { NavBar, Footer } from 'src/ui/common'
import Wallet from '../Wallet'
import { AgreementList } from './AgreementList'

export function AgreementsPage () {
  return (
    <div className="page">
      <NavBar teosto />
      <div className="agreements teosto">
        <AgreementList />
        <Wallet />
      </div>
      <Footer />
    </div>
  )
}
