import React, { MouseEvent } from 'react'
import cx from 'classnames'

export interface CloseButtonProps {
  onClick: (e: MouseEvent) => void,
  className?: string,
}

export function CloseButton ({ onClick, className }: CloseButtonProps) {
  return (<button className={cx('close-button', className)} onClick={onClick} />)
}
