import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export interface AdminPageProps {
  children: ReactNode,
}

export function AdminPage ({ children }: AdminPageProps) {
  return (
    <Page>
      <Sidebar>
        <List>
          <Item><Link to='/admin/fees'>Fees</Link></Item>
          <Item><Link to='/admin/cashouts'>Cashouts</Link></Item>
          <Item><Link to='/admin/scan-and-register'>Scan and Register</Link></Item>
          <Item><Link to='/admin/redeem'>Redeem</Link></Item>
          <Item><Link to='/admin/micro-lending'>Micro Lending</Link></Item>
        </List>
      </Sidebar>
      <Content>{children}</Content>
    </Page>
  )
}

const Page = styled.div`
  min-height: 100vh;
  display: flex;
`

const Sidebar = styled.nav`
  width: 250px;
  background-color: white;
  padding: 40px 0;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
`

const List = styled.ul`
  margin: 0;
  padding: 0;
`

const Item = styled.li`
`

const Link = styled(NavLink)`
  display: block;
  padding: 10px 20px;
  color: gray;

  &.active {
    background-color: rgb(220, 220, 255);
    border-left: 5px solid rgb(128, 128, 255);
    padding-left: 15px;
    color: blue;
  }
`

const Content = styled.main`
  flex: 1;
  padding: 0 20px;
`
