import React from 'react'
import cx from 'classnames'

import { ApiAssetContract } from 'original-works-core/models'

export interface AssetFieldProps {
  contract: ApiAssetContract,
  title: string,
  bottom?: boolean,
}

export function AssetField ({ contract, title, bottom }: AssetFieldProps) {
  return (
    <div className={cx('activity-modal-field', bottom && 'activity-modal-field--bottom')}>
      <span className="activity-modal-field__title">{title}</span>
      <div className="activity-modal-contract__wrapper">
        <img src={contract.factsheet.artwork} alt="icon" className="activity-modal-contract__icon"/>
        <div className="activity-modal-contract__description">
          <p className="activity-modal-contract__title">
            {contract.factsheet.title}
          </p>
          <p className="activity-modal-contract__text">
            {contract.factsheet.type} · {contract.factsheet.subtitle}
          </p>
        </div>
      </div>
    </div>
  )
}
