import { css } from 'styled-components'

export const multilineEllipsis = (lineHeight: string, lineCount: number) => css`
  overflow: hidden;
  position: relative;
  line-height: ${lineHeight};
  max-height: calc(${lineHeight} * ${lineCount});
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  text-align: justify;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }
`
