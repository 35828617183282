import React from 'react'
import { EarningsDataItem, Period } from 'original-works-core/utils'
import { Bar } from './Bar'
import Big from 'big.js'

export interface DataBarsProps {
  entries: EarningsDataItem[],
  isAsset: boolean,
  maxChartValue: number,
  period: Period,
}

export function DataBars ({ period, entries, maxChartValue, isAsset }: DataBarsProps) {
  return (
    <div className="eh-bar__wrapper">
      {entries.map((entry, index) => {
        const bothZero = (!entry.earned || entry.earned.isZero()) && entry.received.isZero()
        return (
          <Bar
            period={period}
            entry={entry}
            key={index}
            active={true}
            type={bothZero ? 'zero' : isAsset ? 'asset' : 'agreement'}
            receivedHeight={getPercentage(entry.received.value, maxChartValue)}
            earnedHeight={(entry.earned && getPercentage(entry.earned.value, maxChartValue)) || '0'}
          />
        )
      })}
    </div>
  )
}

const getPercentage = (a: Big, b: number) => a.div(b).mul(100).toString()
