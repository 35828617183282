import React, { FormEvent, ReactNode } from 'react'
import { bem } from 'bem-components-react'

export interface CreatorProps {
  title?: string,
  subtitle?: string,
  twoColumns?: boolean,
  buttonContent: ReactNode,
  disabled?: boolean,
  disallowEnterSubmit?: boolean,
  onSubmit: () => void,
  children: ReactNode,
}

export function Creator (props: CreatorProps) {
  function onSubmit (e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    props.onSubmit()
  }

  return (
    <Component onSubmit={onSubmit}>
      {props.disallowEnterSubmit && (
        <button
          type="submit"
          disabled
          style={{ display: 'none' }}
          aria-hidden="true"
        />
      )}
      {props.title && <CreatorTitle>{props.title}</CreatorTitle>}
      {props.subtitle && <CreatorSubtitle>{props.subtitle}</CreatorSubtitle>}
      {props.twoColumns
        ? <CreatorColumns>{props.children}</CreatorColumns>
        : props.children
      }
      <CreatorButton disabled={props.disabled}>
        {props.buttonContent}
      </CreatorButton>
    </Component>
  )
}

const Component = bem.form('creator')
const CreatorTitle = bem.h1('creator__title')
const CreatorSubtitle = bem.p('creator__subtitle')
const CreatorColumns = bem.div('creator__columns')
const CreatorButton = bem.button('creator__button')
