import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

import { useObservable, useServices } from './hooks'
import { LoginRedirect } from './LoginRedirect'

export function PrivateRoute (props: RouteProps) {
  const { accountService } = useServices()
  const [address] = useObservable(() => accountService.getAddressObservable(), [])

  if (address === undefined) {
    return null
  } else if (address) {
    return <Route {...props} />
  } else {
    return <LoginRedirect/>
  }
}
