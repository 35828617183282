import React from 'react'

import { useAsync, useServices } from 'src/ui/hooks'
import { ProfileImage } from './ProfileImage'

export interface UserProfileProps {
  userAddress: string,
}

export function UserProfile ({ userAddress }: UserProfileProps) {
  const { api, accountService } = useServices()
  const [profile] = useAsync(() => api.getUserDetails(userAddress), [userAddress])

  return (
    <div className="user-profile">
      <ProfileImage src={profile?.imageURL} />
      <p className="user-profile__name">
        {profile ? profile.name : '...'}
        {accountService.isSignedIn() && accountService.getAddress() === userAddress && ' (You)'}
      </p>
    </div>
  )
}
