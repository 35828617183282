import React from 'react'
import { bem } from 'bem-components-react'

export interface SwitchProps {
  value: boolean,
  onChange (value: boolean): void,
}

export function Switch ({ value, onChange }: SwitchProps) {
  return (
    <Container>
      <CheckBox
        checked={value}
        onChange={e => onChange(e.target.checked)}
        type="checkbox"
      />
      <Slider><SliderHandle /></Slider>
    </Container>
  )
}

const Container = bem.label('switch')
const CheckBox = bem.input('switch__checkbox')
const Slider = bem.div('switch__slider')
const SliderHandle = bem.div('switch__slider-handle')
