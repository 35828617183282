import React from 'react'
import cx from 'classnames'
import { BarModal } from './BarModal'
import { EarningsDataItem, Period } from 'original-works-core/utils'

export interface BarProps {
  entry: EarningsDataItem,
  type: 'zero' | 'asset' | 'agreement',
  active: boolean,
  receivedHeight: string,
  earnedHeight?: string,
  period: Period,
}

export function Bar ({ period, entry, type, active, receivedHeight, earnedHeight }: BarProps) {
  return (
    <div className="eh-bar__field">
      {type === 'zero' && (
        <div className="eh-bar eh-bar--zero" />
      )}
      {type === 'agreement' && (
        <div
          className={cx('eh-bar', active || 'eh-bar--inactive')}
          style={{ height: `${receivedHeight}%` }}
        />
      )}
      {type === 'asset' && (
        <>
          <div
            className={cx('eh-bar', 'eh-bar--left', active || 'eh-bar--inactive')}
            style={{ height: `${earnedHeight}%` }}
          />
          <div
            className={cx('eh-bar', 'eh-bar--right', active || 'eh-bar--inactive')}
            style={{ height: `${receivedHeight}%` }}
          />
        </>
      )}
      <BarModal entry={entry} period={period}/>
    </div>
  )
}
