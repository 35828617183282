import React, { ReactNode } from 'react'
import cx from 'classnames'

import { PayToUserCloseButton } from './PayToUserCloseButton'
import { PayToUserNavBar } from './PayToUserNavBar'

export interface PayToUserPageProps {
  children: ReactNode,
  onClose?: () => void,
  className?: string,
}

export function PayToUserPage ({ children, onClose, className }: PayToUserPageProps) {
  return (
    <div className={cx('pay-to-user', className)}>
      <PayToUserNavBar />
      <div className="pay-to-user_modal">
        {onClose && <PayToUserCloseButton onClick={onClose} />}
        {children}
      </div>
    </div>
  )
}
