import {
  AgreementQueryService,
  BalanceService,
  BalanceChecker,
  ExchangeRateService,
  PayoutQueryService,
  ContractActivityService,
  AddressUtilsService,
} from 'original-works-core/services'
import { StripeService } from 'src/services/StripeService'

import { AccountService } from './AccountService'
import { AgreementDetailsService } from './agreements/AgreementDetailsService'
import { AgreementListService } from './agreements/AgreementListService'
import { Api } from './Api'
import { CacheService } from './CacheService'
import { CashoutService } from './CashoutService'
import { Config } from '../config/Config'
import { PersistentStorage } from './PersistentStorage'
import { RedeemService } from './RedeemService'
import { TeostoPayoutService } from './TeostoPayoutService'
import { UserService } from './UserService'
import { ContractQueryService } from './ContractQueryService'

const DEFAULT_REFRESH_INTERVAL = 10000

export type Services = ReturnType<typeof createServices>

export function createServices (config: Config) {
  const api = new Api(config.apiUrl)
  const addressUtilsService = new AddressUtilsService(config.provider)
  const cashoutService = new CashoutService(api, config.provider, config.OWNTokenAddress, config.cashoutAddress)
  const storage = new PersistentStorage()
  const accountService = new AccountService(storage)
  const agreementDetailsService = new AgreementDetailsService(api)
  const userService = new UserService(api)

  const cacheService = new CacheService()
  const exchangeRateService = new ExchangeRateService(api, cacheService)
  const balanceChecker = new BalanceChecker(accountService, config.provider, config.OWNTokenAddress, config.nativeCoin)
  const balanceService = new BalanceService(cacheService, balanceChecker, exchangeRateService, 10000)

  const teostoPayoutService = new TeostoPayoutService(api, accountService)
  const payoutQueryService = new PayoutQueryService(api, config.provider)
  const contractActivityService = new ContractActivityService(api, payoutQueryService)

  const redeemService = new RedeemService(api, accountService, config.campaignId, config.campaignApiKey)

  const agreementQueryService = new AgreementQueryService(api, accountService, cacheService, DEFAULT_REFRESH_INTERVAL)
  const agreementListService = new AgreementListService(agreementQueryService)
  const contractQueryService = new ContractQueryService(
    api,
    accountService,
    cacheService,
    10000,
  )

  const stripeService = new StripeService(api, config.stripeKey)

  return {
    accountService,
    addressUtilsService,
    agreementDetailsService,
    agreementListService,
    api,
    balanceService,
    cashoutService,
    contractActivityService,
    contractQueryService,
    exchangeRateService,
    payoutQueryService,
    redeemService,
    stripeService,
    teostoPayoutService,
    userService,
  }
}
